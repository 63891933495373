import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCustomerStatus } from "../services/customerService";
import { getCustomerDetails } from "../services/customerService";
import { getCurrentLoanStatus } from "../services/loanPortalService";
import { getRewardsDetails } from "../services/rewardsService";
import { Customer } from "../types/genericType";

interface ApiState {
  loanStatus: any;
  customerLoanStatus: any;
  currentLoanStatus: any;
  rewardsDetails: any;
  customerDetails: Customer;
  isLoading: boolean;
}

export const fetchLoanStatus = createAsyncThunk(
  "api/fetchLoanStatus",
  async () => {
    const customerStatus = await getCustomerStatus();
    return customerStatus?.loanStatus;
  }
);

export const fetchRewardsDetails = createAsyncThunk(
  "api/fetchRewardsDetails",
  async () => {
    const rewardsDetails = await getRewardsDetails();
    sessionStorage.setItem("rewardsDetails", JSON.stringify(rewardsDetails));
    return rewardsDetails;
  }
);

export const fetchCurrentLoanStatus = createAsyncThunk(
  "api/fetchCurrentLoanStatus",
  async () => {
    const currentLoanStatus = await getCurrentLoanStatus();
    return currentLoanStatus;
  }
);

export const fetchCustomerDetails = createAsyncThunk(
  "api/fetchCustomerDetails",
  async () => {
    const customerDetails = await getCustomerDetails();
    sessionStorage.setItem("customerDetails", JSON.stringify(customerDetails));
    return customerDetails;
  }
);

const apiSlice = createSlice({
  name: "api",
  initialState: {
    loanStatus: null,
    customerLoanStatus: null,
    currentLoanStatus: null,
    rewardsDetails: null,
    customerDetails: {} as Customer,
    isLoading: false,
  } as ApiState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoanStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchLoanStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.customerLoanStatus = action.payload;
        state.loanStatus = action.payload?.current?.status;
      })
      .addCase(fetchLoanStatus.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchRewardsDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchRewardsDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.rewardsDetails = action.payload;
      })
      .addCase(fetchRewardsDetails.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchCurrentLoanStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCurrentLoanStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentLoanStatus = action.payload;
      })
      .addCase(fetchCurrentLoanStatus.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchCustomerDetails.fulfilled, (state, action) => {
        state.customerDetails = action.payload;
      });
  },
});

export default apiSlice.reducer;

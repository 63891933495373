import { ExpandMoreOutlined } from "@mui/icons-material";
import {
  Toolbar,
  Typography,
  Button,
  Box,
  Menu,
  MenuItem,
  Container,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

type MenuComponentProps = {
  menuOpen: boolean;
  handleMenuToggle: any;
};

const MobileMenu = (props: MenuComponentProps) => {
  const { menuOpen, handleMenuToggle } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);

  const { customerLoanStatus, currentLoanStatus, customerDetails } =
    useSelector((state: RootState) => state.api);
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEl1);
  const location = useLocation();

  const [actionButtonText, setActionButtonText] = useState("");
  const [actionButtonLink, setActionButtonLink] = useState("");

  useEffect(() => {
    if (location.pathname === "/rewards") {
      setActionButtonText("Cart");
      setActionButtonLink("/rewards/cart");
    } else if (location.pathname === "/rewards/cart") {
      setActionButtonText("Continue Shopping");
      setActionButtonLink("/rewards");
    } else if (customerLoanStatus?.reloanDetails?.makePayment) {
      setActionButtonText("Make Payment");
      setActionButtonLink("/payment");
    } else if (
      !customerLoanStatus?.hasPendingLoan &&
      customerLoanStatus?.hasActiveLoan
    ) {
      setActionButtonText("Request New Loan");
      setActionButtonLink("/apply/uploan");
    } else if (
      !customerLoanStatus?.hasPendingLoan &&
      customerLoanStatus?.hasCompletedLoan
    ) {
      setActionButtonText("Request New Loan");
      setActionButtonLink("/apply/reloan");
    } else {
      setActionButtonText("");
    }
  }, [customerLoanStatus, location.pathname]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigationMobile = (path: string) => {
    setAnchorEl(null);
    setAnchorEl1(null);
    navigate(path);
    handleMenuToggle();
  };

  const onActionButtonClick = () => {
    handleMenuToggle();
    navigate(actionButtonLink);
  };

  const listItemStyle = {
    width: "100%",
    display: "inline-block",
    padding: "0 10px",
    color: "white",
    border: "1px solid white",
    borderRadius: "5px",
    margin: "5px 0",
  };

  const navigateTo = (path: string) => {
    navigate(path);
    handleMenuToggle();
  };

  return (
    <Container sx={{ padding: "60px 0 0 0" }}>
      <Box
        sx={{
          display: menuOpen ? "block" : "none",
          position: "fixed",
          top: "55px",
          left: 0,
          right: 0,
          background: "#00db8f",
          padding: "5px 16px",
          zIndex: 1100,
        }}
      >
        <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
          <li style={listItemStyle}>
            <Button
              sx={{
                color: "white",
                fontSize: "14px",
                textTransform: "capitalize",
              }}
              onClick={() => navigateTo("/Portal")}
            >
              Home
            </Button>
          </li>
          <li style={listItemStyle}>
            <Button
              sx={{
                color: "white",
                fontSize: "14px",
                textTransform: "capitalize",
              }}
              onClick={() => navigateTo("/rewards")}
            >
              Redeem
            </Button>
          </li>
          <li style={listItemStyle}>
            <Button
              sx={{
                color: "white",
                fontSize: "14px",
                textTransform: "capitalize",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
              id="account-button"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <span>Account</span>
              <ExpandMoreOutlined />
            </Button>
            <Menu
              id="account-menu-mob"
              anchorEl={anchorEl}
              open={open}
              key="account-menu-mob"
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "account-button",
              }}
            >
              <MenuItem
                key="profile-menu-mob"
                onClick={() => handleNavigationMobile("/profile")}
                sx={{ fontSize: "14px", textTransform: "capitalize" }}
              >
                My Profile
              </MenuItem>
              <MenuItem
                onClick={() => handleNavigationMobile("/loan-history")}
                sx={{ fontSize: "14px", textTransform: "capitalize" }}
              >
                Loan History
              </MenuItem>
            </Menu>
          </li>
          {customerLoanStatus?.hasActiveLoan && (
            <li style={listItemStyle}>
              <>
                <Button
                  sx={{
                    color: "white",
                    fontSize: "14px",
                    textTransform: "capitalize",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                  id="payment-button"
                  aria-controls={open1 ? "payment-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open1 ? "true" : undefined}
                  onClick={(event) => setAnchorEl1(event.currentTarget)}
                >
                  <span>Payments</span>
                  <ExpandMoreOutlined />
                </Button>
                <Menu
                  id="payment-menu"
                  anchorEl={anchorEl1}
                  open={open1}
                  onClose={() => setAnchorEl1(null)}
                  MenuListProps={{
                    "aria-labelledby": "payment-button",
                  }}
                >
                  {currentLoanStatus?.enablePayment && (
                    <MenuItem
                      onClick={() => handleNavigationMobile("/payment")}
                      sx={{ fontSize: "14px", textTransform: "capitalize" }}
                    >
                      Make a Payment
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() =>
                      handleNavigationMobile(
                        `/loans/:${currentLoanStatus?.id}/payments`
                      )
                    }
                    sx={{ fontSize: "14px", textTransform: "capitalize" }}
                  >
                    Payment History
                  </MenuItem>
                </Menu>
              </>
            </li>
          )}
          {actionButtonText && (
            <li style={{ ...listItemStyle, padding: 0 }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#004d40",
                  width: "100%",
                  color: "#fff",
                  fontSize: "14px",
                  height: "48px",
                  ":hover": {
                    backgroundColor: "#004d40",
                  },
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                onClick={onActionButtonClick}
              >
                {actionButtonText}
                <ChevronRightIcon />
              </Button>
            </li>
          )}
        </ul>
      </Box>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography
            variant="body1"
            sx={{ fontSize: "14px", color: "#5a5a5b" }}
          >
            Welcome Back,
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", fontSize: "14px", color: "#5a5a5b" }}
          >
            {customerDetails?.firstName} {customerDetails?.lastName}
          </Typography>
        </Box>
      </Toolbar>
    </Container>
  );
};

export default MobileMenu;

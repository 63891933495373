import "./index.css";
import { Provider } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import CssBaseline from "@mui/material/CssBaseline";
import AppWrapper from "./AppWrapper";
import { store } from "./redux/store";
import ErrorPage from "./components/errorBoundary/ErrorPage";
import globalRouter from "./core/globalRouter";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";

const theme = createTheme({
  typography: {
    fontFamily: "'AvenirNext-Medium', 'AvenirNext', sans-serif",
    allVariants: {
      letterSpacing: "0.02em",
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#838588 !important",
            border: ".5px solid",
          },
        },
      },
    },
  },
});

const CssThemeProvider = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppWrapper />
    </ThemeProvider>
  );
};

const App = () => {
  const navigate = useNavigate();
  globalRouter.navigate = navigate;

  return (
    <Provider store={store}>
      <ErrorBoundary fallback={<ErrorPage />}>
        <CssThemeProvider />
      </ErrorBoundary>
    </Provider>
  );
};

export default App;

import React from "react";
import { Button } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

interface GenericButtonProps {
  label: string;
  onClick: () => void;
  additionalStyles?: object;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

const GenericButton: React.FC<GenericButtonProps> = ({
  label,
  onClick,
  additionalStyles = {},
  startIcon,
  endIcon = <ChevronRightIcon />,
}) => {
  return (
    <Button
      variant="contained"
      sx={{
        backgroundColor: "#004d40",
        color: "#fff",
        fontSize: "14px",
        textTransform: "capitalize",
        whiteSpace: "nowrap",
        height: "48px",
        ":hover": {
          backgroundColor: "#004d40",
        },
        ...additionalStyles,
      }}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {label}
    </Button>
  );
};

export default GenericButton;

import { Modal, Paper, Box, Button, Typography } from "@mui/material";
import React from "react";
import { ModalProps } from "../../types/genericType";

const PrivacyPolicyModal = (props: ModalProps) => {
  const { open, handleClose } = props;

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper
        sx={{
          maxWidth: "700px",
          height: "90vh",
          overflowY: "auto",
          padding: 0,
          margin: "80px auto !important",
          borderRadius: 0,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#D3D3D3",
            }}
            onClick={handleClose}
          >
            X
          </Button>
        </Box>
        <Typography
          sx={{
            fontSize: { xs: "20px", sm: "24px" },
            fontWeight: "400",
            textAlign: "center",
            color: "#4d4d4d",
            marginTop: "40px",
          }}
          gutterBottom
        >
          Privacy Policy
        </Typography>
        <Box
          sx={{
            color: "#838588",
            padding: { xs: "30px 30px", sm: "30px 50px" },
            fontSize: { xs: "10px", sm: "14px" },
          }}
        >
          <h4>WHAT DOES LIFT CREDIT, LLC DO WITH YOUR PERSONAL INFORMATION?</h4>

          <p>
            Lift Credit is in the financial industry, and thus carefully decides
            how to share your personal information. As a consumer, Federal law
            allows you to limit the sharing of some of your personal
            information, but not all of it. Federal law requires us to tell you
            how we gather, share, and protect your personal information. This
            Policy will help you understand what we do, and what you can do
            regarding your personal information — please read it carefully. If
            you ever have any questions, or want to opt out at any time, you may
            reach us at:
          </p>

          <p>
            3214 N. University Ave. #601
            <br />
            Provo, UT 84604
            <br />
            1-800-223-5858
            <br />
            support@liftcredit.com
            <br />
          </p>

          <p>
            The types of personal information collected and shared by Lift
            Credit depend on the loan product you are applying for, or currently
            have with us. We will, in almost every case, collect the following
            personal information from you:
          </p>

          <p>
            Social Security Number
            <br />
            State Issued Identification Number
            <br />
            Street Address of Your Current Residence
            <br />
            Employment Information
            <br />
            Telephone Numbers and Email Addresses
            <br />
            Color Copy of your Photo ID
            <br />
            Paycheck Stubs
            <br />
          </p>

          <p>
            Because Lift Credit is a financial company that is disbursing money
            to you, we need to share your information with our partners in order
            to protect ourselves and get you the best loan product available to
            you.
          </p>

          <p>This Privacy Policy and related notice is provided by:</p>

          <p>Lift Credit, LLC. A Utah Limited Liability Company</p>
          <p>
            Because we take your privacy seriously, we have taken steps to
            protect your personal information from anyone that is not authorized
            by Lift Credit. Our facility has been inspected and complies with
            federal law regarding access to your personal information and
            protection from unauthorized persons. We use recommended building
            and computer safeguards to secure your information.
          </p>

          <p>
            You need to be aware that we are collecting and storing information
            when, for example, you complete a credit application, allow us to
            run a credit report, authorize us to confirm your employment status,
            opt into our text notification service, talk to an employee of Lift
            Credit on the phone and verbally share information, login to your
            customer account portal and update information, etc.
          </p>

          <p>
            Although you are able to limit the sharing of some of your
            information, Federal Law allows us to share certain information for
            the purposes of conducting our business. The information outlined
            above regarding how Lift Credit shares your personal information and
            provides you with multiple ways to OPT OUT is provided for you to
            educate and protect yourself. We are here to help if you have any
            concerns. Please read and understand this and other Lift Credit
            Policies prior to sharing your personal information. It is your
            responsibility to understand and contact us with concerns regarding
            this or any policy of Lift Credit. Our contact information is
            provided multiple times on each document and on our website for your
            convenience.
          </p>

          <p>
            We use certain bank account information in the collection of
            defaulted loans. If we extend credit to a consumer, we will consider
            the bank account information provided by the consumer as eligible
            for us to process payments against. In addition, as part of our
            information collection process, we may detect additional bank
            accounts under the ownership of the consumer. We will consider these
            additional accounts to be part of the application process.
          </p>

          <p>CONTACT INFORMATION FOR Lift Credit, LLC:</p>

          <p>
            Lift Credit
            <br />
            3214 N. University Ave. #601
            <br />
            Provo, UT 84604
            <br />
            1-800-223-5858
            <br />
            support@liftcredit.com
            <br />
          </p>
        </Box>
      </Paper>
    </Modal>
  );
};

export default PrivacyPolicyModal;

import React, { useState, useEffect } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import {
  LoanState,
  NewLoanApplication,
  StepState,
} from "../../types/genericType";
import { useParams } from "react-router-dom";

const labelsMap = {
  loanAmount: "Enter Loan Amount",
  customerInfo: "Update Personal Info",
  loanDocs: "Upload Documents",
};

const initialLoanState: LoanState = {
  currentStep: "loanAmount",
  loanApplication: {
    loanRequestType: "Reloan",
    loanType: "PersonalLoan",
    primaryAddress: {},
    agreeToConsent: true,
    consentToMarketing: true,
    optInToMarketingMessage: false,
    optInToReceiveTextMessage: false,
    activeMilitary: "no",
    bankVerificationRequestResult: true,
    repaymentMethod: "Ach",
    loanSignature: {},
    verifyBank: "No",
    loanEligibility: {},
    validClarity: false,
    incomeSourceConfirmed: false,
    validationMessage: "",
    loanAmount: "",
    useExistingPhotoId: true,
  },
};

function useLocalStorage<T>(
  key: string,
  initialValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error("Error reading localStorage key", key, error);
      return initialValue;
    }
  });

  useEffect(() => {
    try {
      window.localStorage.setItem(key, JSON.stringify(storedValue));
    } catch (error) {
      console.error("Error setting localStorage key", key, error);
    }
  }, [key, storedValue]);

  return [storedValue, setStoredValue];
}

const stepsMap: {
  [key in StepState]: React.FC<{
    updateLoanApplication: (data: Partial<NewLoanApplication>) => void;
    handleNextStep: () => void;
    handlePreviousStep: () => void;
  }>;
} = {
  loanAmount: Step1,
  customerInfo: Step2,
  loanDocs: Step3,
};

const NewLoan: React.FC = () => {
  const [loanState, setLoanState] = useLocalStorage<LoanState>(
    "loanState",
    initialLoanState
  );
  const { loanRequestType } = useParams();

  useEffect(() => {
    if (loanRequestType) {
      setLoanState((prev) => ({
        ...prev,
        loanApplication: {
          ...prev.loanApplication,
          loanRequestType: loanRequestType as "Reloan" | "Uploan",
        },
      }));
    }
  }, [loanRequestType, setLoanState]);

  const handleNextStep = () => {
    const nextStepIndex =
      Object.keys(stepsMap).indexOf(loanState.currentStep) + 1;
    const nextStep = Object.keys(stepsMap)[nextStepIndex] as StepState;
    if (nextStep) {
      setLoanState((prev) => ({ ...prev, currentStep: nextStep }));
    }
  };

  const handlePreviousStep = () => {
    const prevStepIndex =
      Object.keys(stepsMap).indexOf(loanState.currentStep) - 1;
    const prevStep = Object.keys(stepsMap)[prevStepIndex] as StepState;
    if (prevStep) {
      setLoanState((prev) => ({ ...prev, currentStep: prevStep }));
    }
  };

  const updateLoanApplication = (data: Partial<NewLoanApplication>) => {
    setLoanState((prev) => ({
      ...prev,
      loanApplication: { ...prev.loanApplication, ...data },
    }));
  };

  const CurrentStepComponent = stepsMap[loanState.currentStep];

  return (
    <Box>
      <Tabs
        value={loanState.currentStep}
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
        sx={{
          overflow: "visible",
          "& .MuiTab-root:first-of-type": { paddingLeft: "15px" },
          "& .MuiTab-root.Mui-selected": {
            cursor: "pointer",
            backgroundColor: "#838588",
            color: "#fff",
            border: "none",
            outline: "none",
            display: "inline-block",
            height: "50px",
          },
          "& .MuiTab-root:focus": {
            backgroundColor: "#838588",
            color: "#fff",
          },
        }}
      >
        {Object.keys(stepsMap).map((step, index) => {
          const isActive = loanState.currentStep === step;
          const isCompleted =
            Object.keys(stepsMap).indexOf(loanState.currentStep) >= index;
          return (
            <Tab
              key={step}
              label={labelsMap[step as StepState]}
              value={step}
              disableRipple
              sx={{
                textTransform: "none",
                width: "30%",
                height: "50px",
                position: "relative",
                marginLeft: index === 0 ? "0" : "10px",
                fontSize: { xs: "10px", sm: "12px" },
                maxHeight: "50px",
                backgroundColor:
                  isActive || isCompleted ? "#838588" : "transparent",
                color: isActive || isCompleted ? "#fff" : "#838588",
                cursor: "default",
                pointerEvents: "none",
                "&:hover": {
                  backgroundColor:
                    isActive || isCompleted ? "#838588" : "transparent",
                },
                "&::after": {
                  content: "''",
                  position: "absolute",
                  transform: "translateY(-50%)",
                  right: "-25px",
                  borderStyle: "solid",
                  borderWidth: "25px",
                  borderLeftColor: "transparent",
                  top: "50%",
                  display: isActive ? "block" : "none",
                },
              }}
            />
          );
        })}
      </Tabs>
      <Box sx={{ mt: 4 }}>
        <CurrentStepComponent
          updateLoanApplication={updateLoanApplication}
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
        />
      </Box>
    </Box>
  );
};

export default NewLoan;

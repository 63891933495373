import React, { useEffect, useState } from "react";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import { Container, Typography, Stepper, Step, StepLabel } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const steps = ["", "", "", "", ""];
const routes = [
  "/",
  "/apply/info",
  "/apply/income",
  "/apply/accounts",
  "/apply/login",
];

const LoanApplicationForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [step, setStep] = useState(1);

  useEffect(() => {
    const currentPath = location.pathname;
    const currentStepIndex = routes.indexOf(currentPath);
    if (currentStepIndex !== -1) {
      setStep(currentStepIndex + 1);
    }
  }, [location]);

  const nextStep = () => {
    if (step < routes.length) {
      const nextStepIndex = step;
      navigate(routes[nextStepIndex]);
    }
  };

  const prevStep = () => {
    if (step > 1) {
      const prevStepIndex = step - 2;
      navigate(routes[prevStepIndex]);
    }
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 1:
        return <StepOne nextStep={nextStep} />;
      case 2:
        return <StepTwo nextStep={nextStep} prevStep={prevStep} />;
      case 3:
        return <StepThree prevStep={prevStep} nextStep={nextStep} />;
      case 4:
        return <StepFour prevStep={prevStep} nextStep={nextStep} />;
      case 5:
        return <StepFive prevStep={prevStep} />;
      default:
        return <StepOne nextStep={nextStep} />;
    }
  };

  return (
    <Container sx={{ padding: "0px" }}>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{ color: "#4d4d4d", fontSize: "24px" }}
      >
        Apply Online
      </Typography>
      <Stepper
        activeStep={step - 1}
        alternativeLabel
        sx={{ height: "50px", padding: "10px 80px" }}
      >
        {steps.map((label) => (
          <Step
            sx={{
              "& .MuiStepLabel-root .Mui-disabled": {
                color: "#00db8f !important",
              },
              "& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel":
                {
                  color: "#00db8f",
                },
              "& .MuiStepLabel-root .Mui-completed": {
                color: "#00db8f",
              },
              "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                {
                  color: "#00db8f",
                },
              "& .MuiStepLabel-root .Mui-active": {
                color: "#00db8f",
              },
              "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                {
                  color: "#00db8f",
                },
              "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                fill: "white",
              },
            }}
            key={label}
          >
            <StepLabel sx={{ color: "red" }}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {getStepContent(step)}
    </Container>
  );
};

export default LoanApplicationForm;

import { AppBar, Box, Button, Grid, Toolbar, Typography } from "@mui/material";
import React, { useContext } from "react";
import Logo from "../../assets/images/logo.png";
import CallIcon from "@mui/icons-material/Call";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../core/authContext";
import { MenuOutlined } from "@mui/icons-material";

type TopBarProps = {
  handleMenuToggle: any;
};

const TopBar = (props: TopBarProps) => {
  const { handleMenuToggle } = props;
  const navigate = useNavigate();
  const userDetails = useContext(AuthContext);

  const handleLogin = () => {
    if (userDetails?.isLoggedIn) {
      localStorage.clear();
      userDetails.setIsSigningOut(true);
    }
    navigate("/login");
  };

  return (
    <AppBar
      position="fixed"
      className="top-bar"
      sx={{ backgroundColor: "#00db8f" }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingLeft: 0,
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ maxWidth: "1180px", width: "100%", mx: "auto" }}
        >
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            {userDetails?.isLoggedIn && (
              <Button
                onClick={handleMenuToggle}
                sx={{
                  width: "40px",
                  height: "40px",
                  color: "white",
                  padding: "2px",
                  display: { xs: "block", sm: "none" },
                }}
              >
                <MenuOutlined
                  sx={{
                    fontSize: "40px",
                    border: "1px solid white",
                    borderRadius: "5px",
                    padding: "4px",
                  }}
                />
              </Button>
            )}
            <Box
              component="img"
              sx={{ height: 35, marginLeft: { xs: "16px", sm: 0 } }}
              alt="Your logo."
              src={Logo}
            />
          </Grid>
          <Grid item>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                textAlign: "left",
              }}
            >
              <CallIcon
                sx={{
                  color: "white",
                  marginRight: 1,
                  display: { xs: "none", sm: "block" },
                }}
              />
              <Typography
                sx={{
                  color: "white",
                  marginRight: 1,
                  display: { xs: "none", sm: "block" },
                }}
              >
                <a
                  href="tel:800-223-5858"
                  style={{
                    cursor: "pointer",
                    color: "white",
                    textDecoration: "none",
                  }}
                >
                  1-800-223-5858
                </a>
              </Typography>
              <Button
                variant="outlined"
                sx={{
                  color: "white",
                  borderColor: "white",
                  ":hover": { borderColor: "white" },
                }}
                onClick={handleLogin}
              >
                {userDetails?.isLoggedIn ? "SIGN OUT" : "SIGN IN"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;

import { AxiosResponse } from "axios";
import { apiEndPoints } from "../constants/apiEndpoints";
import axiosInstance from "../core/axios";
import {
  Customer,
  LoanApprovalType,
  LoanStatus,
  RewardHistoryResponse,
  RewardSummary,
} from "../types/genericType";

export const getAdminCustomerDetails = async ({
  customerId,
}: {
  customerId: string;
}) => {
  try {
    const response: AxiosResponse<Customer> = await axiosInstance.get(
      `${apiEndPoints.adminCustomerDetails}/${customerId}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getAdminCustomerLoanDetails = async ({
  customerId,
}: {
  customerId: string;
}) => {
  try {
    const response: AxiosResponse<LoanStatus> = await axiosInstance.get(
      `${apiEndPoints.adminCustomerDetails}/${customerId}/loans/status`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getRewardsSummary = async ({
  customerId,
}: {
  customerId: string;
}) => {
  try {
    const response: AxiosResponse<RewardSummary> = await axiosInstance.get(
      `${apiEndPoints.adminRewardSummary}/${customerId}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getRewardsTransactionHistory = async ({
  customerId,
  page,
  perPage,
}: {
  customerId: string;
  page: number;
  perPage: number;
}) => {
  try {
    const response: AxiosResponse<RewardHistoryResponse> =
      await axiosInstance.get(
        `${apiEndPoints.adminRewardTransactions}/${customerId}?page=${page}&perPage=${perPage}`
      );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getLoanApprovalAmount = async ({
  customerId,
  netMonthlyIncome,
}: {
  customerId: string;
  netMonthlyIncome: string;
}) => {
  try {
    const response: AxiosResponse<LoanApprovalType> = await axiosInstance.get(
      `${apiEndPoints.adminCustomerDetails}/${customerId}/loans/approval?netMonthlyIncome=${netMonthlyIncome}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getReportLink = async ({
  loanId,
  reportName,
}: {
  loanId: number;
  reportName: string;
}) => {
  try {
    const response: AxiosResponse<string | null> = await axiosInstance.get(
      `${apiEndPoints.adminLoans}/${loanId}/${reportName}/ReportLink`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const sendApprovalNotification = async ({
  customerId,
  sendViaEmail,
  sendViaSMS,
}: {
  customerId: string;
  sendViaEmail: boolean;
  sendViaSMS: boolean;
}) => {
  try {
    const response: AxiosResponse<void> = await axiosInstance.post(
      `${apiEndPoints.adminCustomerDetails}/${customerId}/loans/approvalnotification`,
      {
        customerId,
        sendViaEmail,
        sendViaSMS,
      }
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

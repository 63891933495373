import { AxiosResponse } from "axios";
import axiosInstance from "../core/axios";
import { apiEndPoints } from "../constants/apiEndpoints";
import {
  CheckEligibilityRequest,
  CheckEligibilityResponse,
  Doc,
  LoanHistoryResponse,
  PaymentHistoryResponse,
  ReloanBodyType,
} from "../types/genericType";

interface PaymentType {
  loanId: string;
  paymentType: string;
}

export const getLoanHistory = async (
  page: number,
  perPage: number
): Promise<LoanHistoryResponse> => {
  try {
    const response: AxiosResponse<LoanHistoryResponse> =
      await axiosInstance.get(
        `${apiEndPoints.loanHistory}?page=${page}&perPage=${perPage}`
      );
    return response?.data || { resources: [], count: 0 };
  } catch (err) {
    throw err;
  }
};

export const getLoanAgreement = async (
  format: string,
  isAdditionalAgreement: boolean
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get(
      `${
        isAdditionalAgreement
          ? apiEndPoints.pendingLoanAgreement
          : apiEndPoints.agreement
      }/${format}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getPaymentHistory = async (
  page: number,
  perPage: number
): Promise<PaymentHistoryResponse> => {
  try {
    const response: AxiosResponse<PaymentHistoryResponse> =
      await axiosInstance.get(
        `${apiEndPoints.paymentHistory}?page=${page}&perPage=${perPage}`
      );
    return response?.data || { resources: [], count: 0 };
  } catch (err) {
    throw err;
  }
};

export const getCurrentLoanStatus = async () => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get(
      `${apiEndPoints.currentLoan}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const checkValidations = async (
  body: any
): Promise<CheckEligibilityResponse> => {
  try {
    const response: AxiosResponse<CheckEligibilityResponse> =
      await axiosInstance.post(`${apiEndPoints.checkEligibility}`, body);
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const checkEligibility = async (
  requestBody: CheckEligibilityRequest
): Promise<CheckEligibilityResponse> => {
  try {
    const response: AxiosResponse<CheckEligibilityResponse> =
      await axiosInstance.post(
        `${apiEndPoints.checkEligibility}/current`,
        requestBody
      );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const uploadDocuments = async (data: Doc[]) => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post(
      `${apiEndPoints.uploadDocument}`,
      data
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const submitReloan = async (data: ReloanBodyType) => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post(
      `${apiEndPoints.loans}`,
      data
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const getPendingLoanDetails = async () => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get(
      `${apiEndPoints.pendingLoan}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getPendingLoanApplicationStep = async (id: number) => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get(
      `${apiEndPoints.loanApplications}/${id}/pendingloanapplicationstep`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getAdvertisingMethods = async (state: string) => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get(
      `${apiEndPoints.advertisingMethods}/PersonalLoan/${state}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getLoanOptions = async (
  loanAmount: string,
  state: string,
  payFrequency: string,
  loanRequestType: string
) => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get(
      `${apiEndPoints.loanOptions}?loanAmount=${loanAmount}&state=${state}&payFrequency=${payFrequency}&loanRequestType=${loanRequestType}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getLendingPartnerStates = async () => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get(
      `${apiEndPoints.lendingPartnerStates}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getEligibilityRequirements = async () => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get(
      `${apiEndPoints.checkEligibility}requirements`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const submitLoanApplication = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post(
      apiEndPoints.loanApplications,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getLendingPartner = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post(
      apiEndPoints.lendingPartner,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getFundingChecklist = async (id: number) => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get(
      `${apiEndPoints.loanApplications}/${id}/fundingchecklist`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const updatePendingLoan = async (data: any) => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.put(
      `${apiEndPoints.loanApplications}/updatependingloan`,
      data
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const updatePreferredPaymentType = async (body: PaymentType) => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.put(
      apiEndPoints.updatePreferredPaymentType,
      body
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const updateAutoExtensionPaymentType = async (body: PaymentType) => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.put(
      apiEndPoints.updateAutoExtensionPaymentType,
      body
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const signLoanAgreement = async (data: any) => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.put(
      apiEndPoints.signLoanAgreement,
      data
    );
    return response;
  } catch (err) {
    throw err;
  }
};

import React from "react";
import TextField from "@mui/material/TextField";
import { InputAdornment, IconButton } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

type AmountFieldPropTypes = {
  label: string;
  amount: string;
  setAmount: Function;
  showHelperText?: Function;
  [key: string]: any;
};
const AmountInputField = (props: AmountFieldPropTypes) => {
  const { label, amount, setAmount, showHelperText, ...restProps } = props;

  const handleBlur = (event: { target: { value: string } }) => {
    const inputValue = parseFloat(event.target.value).toFixed(2);
    setAmount(
      isNaN(parseFloat(event.target.value)) ? "" : inputValue.toString()
    );
  };

  const handleChange = (event: { target: { value: any } }) => {
    const inputValue = event.target.value;
    if (/^\d*\.?\d{0,2}$/.test(inputValue) || inputValue === "") {
      setAmount(inputValue);
    }
  };

  return (
    <TextField
      label={label}
      value={amount === "0" ? "" : amount}
      className="amount-outlined-input"
      onChange={handleChange}
      placeholder=" Enter Loan Amount"
      onBlur={handleBlur}
      variant="outlined"
      InputProps={{
        startAdornment: "$ ",
        endAdornment: showHelperText && (
          <InputAdornment position="end">
            <IconButton onClick={() => showHelperText()}>
              <HelpOutlineIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...restProps}
      size="small"
      fullWidth
    />
  );
};

export default AmountInputField;

import { Typography } from "@mui/material";
import React, { useState } from "react";
import AmountInputField from "../../components/common/AmountField";
import { getLoanApprovalAmount } from "../../services/adminService";
import GenericButton from "../../components/common/GenericButton";
import BackdropLoader from "../../components/loader/BackdropLoader";

type Props = {
  customerId: string;
  setApiError: Function;
  setLoanApproval: Function;
};

const ProcessApproval = (props: Props) => {
  const { customerId, setApiError, setLoanApproval } = props;
  const [amount, setAmount] = useState("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const fetchApprovalAmount = async () => {
    try {
      setLoading(true);
      const data = await getLoanApprovalAmount({
        customerId: customerId,
        netMonthlyIncome: amount,
      });
      setLoanApproval(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setApiError("Failed to fetch loan details");
    }
  };

  const handleGetApprovedLoanAmount = () => {
    if (!amount) {
      setError("Required field");
    } else {
      setError("");
      fetchApprovalAmount();
    }
  };

  return (
    <>
      <Typography
        variant="h6"
        color="#838588"
        fontWeight="bold"
        fontSize={16}
        sx={{ mt: 2, mb: 1 }}
      >
        Process Approval
      </Typography>
      <Typography
        variant="h6"
        sx={{
          fontSize: "12px",
          fontWeight: "600",
          paddingLeft: "8px",
          marginBottom: "5px",
          color: "#838588",
        }}
      >
        Verified Monthly Income
      </Typography>
      <AmountInputField
        label=""
        amount={amount}
        setAmount={(e: any) => setAmount(e)}
        placeholder=" Amount"
        error={!!error}
        helperText={error}
      />
      <Typography sx={{ mt: 2, color: "#838588", fontSize: "12px" }}>
        Clicking 'Get Approved Loan Amount' below will fetch the DataX report
        for the customer, and use both Clarity and DataX report data to retrieve
        a predictive result that helps determine favorability for lending and a
        maximium approved loan amount. If this is run subequent times, uses the
        previously fetched Clarity and DataX reports to predict a result with
        the newly provided monthly income figure.
      </Typography>
      <GenericButton
        label="Get Approved Loan Amount"
        onClick={handleGetApprovedLoanAmount}
        additionalStyles={{ mt: 2, mb: 5 }}
      />
      <BackdropLoader loading={loading} message="" />
    </>
  );
};

export default ProcessApproval;

import { ExpandMoreOutlined } from "@mui/icons-material";
import {
  Toolbar,
  Typography,
  Button,
  Box,
  Menu,
  MenuItem,
  Container,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GenericButton from "../common/GenericButton";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { fetchCustomerDetails } from "../../redux/apiSlice";

const MenuComponent = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);

  const { customerLoanStatus, currentLoanStatus, customerDetails } =
    useSelector((state: RootState) => state.api);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!customerDetails || !customerDetails?.id) {
      dispatch(fetchCustomerDetails());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEl1);
  const location = useLocation();

  const [actionButtonText, setActionButtonText] = useState("");
  const [actionButtonLink, setActionButtonLink] = useState("");

  useEffect(() => {
    if (location.pathname === "/rewards") {
      setActionButtonText("Cart");
      setActionButtonLink("/rewards/cart");
    } else if (location.pathname === "/rewards/cart") {
      setActionButtonText("Continue Shopping");
      setActionButtonLink("/rewards");
    } else if (customerLoanStatus?.reloanDetails?.makePayment) {
      setActionButtonText("Make Payment");
      setActionButtonLink("/payment");
    } else if (
      !customerLoanStatus?.hasPendingLoan &&
      customerLoanStatus?.hasActiveLoan
    ) {
      setActionButtonText("Request New Loan");
      setActionButtonLink("/apply/uploan");
    } else if (
      !customerLoanStatus?.hasPendingLoan &&
      customerLoanStatus?.hasCompletedLoan
    ) {
      setActionButtonText("Request New Loan");
      setActionButtonLink("/apply/reloan");
    } else {
      setActionButtonText("");
    }
  }, [customerLoanStatus, location.pathname]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path: string) => {
    setAnchorEl(null);
    setAnchorEl1(null);
    navigate(path);
  };

  const onActionButtonClick = () => {
    navigate(actionButtonLink);
  };

  return (
    <Container sx={{ padding: "80px 0 0 0" }}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography
            variant="body1"
            sx={{ fontSize: "14px", color: "#5a5a5b" }}
          >
            Welcome Back,
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", fontSize: "14px", color: "#5a5a5b" }}
          >
            {customerDetails?.firstName} {customerDetails?.lastName}
          </Typography>
        </Box>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <Button
            sx={{
              color: "#5a5a5b",
              fontSize: "14px",
              textTransform: "capitalize",
            }}
            onClick={() => navigate("/Portal")}
          >
            Home
          </Button>
          <Button
            sx={{
              color: "#5a5a5b",
              fontSize: "14px",
              textTransform: "capitalize",
            }}
            onClick={() => navigate("/rewards")}
          >
            Redeem
          </Button>
          <Button
            sx={{
              color: "#5a5a5b",
              fontSize: "14px",
              textTransform: "capitalize",
            }}
            id="account-button"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            Account
            <ExpandMoreOutlined />
          </Button>
          <Menu
            id="account-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            key="account-menu"
            MenuListProps={{
              "aria-labelledby": "account-button",
            }}
          >
            <MenuItem
              key="profile-menu"
              onClick={(e) => {
                e.stopPropagation();
                handleNavigation("/profile");
              }}
              sx={{ fontSize: "14px", textTransform: "capitalize" }}
            >
              My Profile
            </MenuItem>
            <MenuItem
              key="loan-menu"
              onClick={() => handleNavigation("/loan-history")}
              sx={{ fontSize: "14px", textTransform: "capitalize" }}
            >
              Loan History
            </MenuItem>
          </Menu>
          {customerLoanStatus?.hasActiveLoan && (
            <>
              <Button
                sx={{
                  color: "#5a5a5b",
                  fontSize: "14px",
                  textTransform: "capitalize",
                }}
                id="payment-button"
                aria-controls={open1 ? "payment-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? "true" : undefined}
                onClick={(event) => setAnchorEl1(event.currentTarget)}
              >
                Payments
                <ExpandMoreOutlined />
              </Button>
              <Menu
                id="payment-menu"
                anchorEl={anchorEl1}
                open={open1}
                onClose={() => setAnchorEl1(null)}
                MenuListProps={{
                  "aria-labelledby": "payment-button",
                }}
              >
                {currentLoanStatus?.enablePayment && (
                  <MenuItem
                    onClick={() => handleNavigation("/payment")}
                    sx={{ fontSize: "14px", textTransform: "capitalize" }}
                  >
                    Make a Payment
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() =>
                    handleNavigation(
                      `/loans/:${currentLoanStatus?.id}/payments`
                    )
                  }
                  sx={{ fontSize: "14px", textTransform: "capitalize" }}
                >
                  Payment History
                </MenuItem>
              </Menu>
            </>
          )}
          {actionButtonText && (
            <GenericButton
              label={actionButtonText}
              onClick={onActionButtonClick}
              additionalStyles={{ ml: 2 }}
            />
          )}
        </Box>
      </Toolbar>
    </Container>
  );
};

export default MenuComponent;

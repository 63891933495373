import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography } from "@mui/material";
import {
  getBankVerificationFailedAttempts,
  getBankVerificationRequestCode,
  getBankVerificationRequestCodeStatus,
  getChirpLinkToken,
  getLokyataScoreData,
} from "../../services/bankVerificationService";
import { getCustomerStatus } from "../../services/customerService";
import ChirpLink from "../../core/ChirpLink";
import ErrorComp from "../../components/errorComp/ErrorComp";
import BackdropLoader from "../../components/loader/BackdropLoader";

declare global {
  interface Window {
    ChirpLink: any;
  }
}

const BankVerificationProcess = () => {
  const [pendingLoan, setPendingLoan] = useState<any | null>(null);
  const [requestCodeDetails, setRequestCodeDetails] = useState<{
    requestCode: string;
    iframeUrl: string;
  } | null>(null);
  const [transition, setTransition] = useState<string | null>(null);
  const [bankVerificationAttemptCount, setBankVerificationAttemptCount] =
    useState<number>(0);
  const [loaderMessage, setLoaderMessage] = useState<string | null>(null);
  const [chirpLinkToken, setChirpLinkToken] = useState<string | null>(null);
  const [openIframe, setOpenIframe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lokyataScore, setLokyataScore] = useState<{
    scoreAvailable: boolean;
    loanDenied: boolean;
    manualReview: boolean;
  } | null>(null);
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    getLoanData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (openIframe) {
      const chirpLink = new ChirpLink({
        token: chirpLinkToken,
        onLoad: function (load: any) {},
        onSuccess: function (successResponse: {
          success: boolean;
          status: string;
        }) {
          if (
            successResponse.success === true &&
            successResponse.status === "VERIFIED"
          ) {
            this.close();
            bankVerificationCallback();
          }
        },
        onError: function (err: { errorMessage: string }) {
          if (err.errorMessage === "Invalid Credentials") {
            verifyAndAddBankVerificationFailedAttempts();
          }
        },
        onClose: function (close: { closedPage: string; pageClosed: boolean }) {
          if (close.closedPage !== "VERIFIED" && close.pageClosed === true) {
            handleIframeClose();
          }
        },
        onBankSelect: function (bank: any) {},
        onAttempt: function (attempt: { attempted: boolean; message: string }) {
          if (
            attempt.attempted === true &&
            attempt.message === "CREDENTIAL_ERROR"
          ) {
            verifyAndAddBankVerificationFailedAttempts();
          }
        },
      });
      chirpLink.on();

      return () => {
        chirpLink.close();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openIframe]);

  const verifyAndAddBankVerificationFailedAttempts = async () => {
    const failedAttemptCounts = await getBankVerificationFailedAttempts(
      pendingLoan?.loanId
    );
    setBankVerificationAttemptCount(failedAttemptCounts);
    if (failedAttemptCounts > 2) {
      setTransition("manualReview");
    }
  };

  const getLoanData = async () => {
    setLoading(true);
    try {
      const loanData = await getCustomerStatus();
      setPendingLoan(loanData?.loanStatus?.pending);

      if (loanData) {
        fetchBankVerificationDetails(loanData?.loanStatus?.pending?.loanId);
      }
      setLoading(false);
    } catch (error) {
      setError("Error fetching loan data");
      setLoading(false);
    }
  };

  const fetchBankVerificationDetails = async (loanId: string) => {
    try {
      const response = await getBankVerificationRequestCode(loanId);
      const data = response?.resource;
      setRequestCodeDetails({
        requestCode: data?.requestCode,
        iframeUrl: data?.iframeUrl,
      });
      if (data?.requestCode) {
        const failedAttemptCounts = await getBankVerificationFailedAttempts(
          loanId
        );
        setBankVerificationAttemptCount(failedAttemptCounts);
        if (failedAttemptCounts > 2) {
          setTransition("manualReview");
        } else {
          const res = await getBankVerificationRequestCodeStatus(loanId);
          if (res && res === "Verified") {
            getLokyataScore();
          } else {
            setLoaderMessage(
              "Please wait while the Bank Verification application loads"
            );
            setLoading(true);
            const token = await getChirpLinkToken(loanId);
            setLoading(false);
            setChirpLinkToken(token);
            setTransition("authorizationToken");
          }
        }
      }
      setLoading(false);
    } catch (error) {
      setError("Error fetching bank verification details");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (transition === "authorizationToken") {
      setOpenIframe(true);
    }
    if (transition === "manualReview") {
      navigate("/Portal/upload");
    } else if (transition === "lokyataScore") {
      validateLokyataDetails();
    } else if (transition === "fundingChecklist") {
      navigate("/Portal/fundingChecklist");
    } else if (transition === "loan") {
      navigate("/Portal");
    } else if (transition === "bankVerification") {
      navigate("/Portal/bankVerification");
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transition, lokyataScore]);

  const bankVerificationCallback = useCallback(
    () => {
      if (pendingLoan) {
        setTimeout(() => {
          getLokyataScore();
        }, 5000);
      } else {
        setTransition("manualReview");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pendingLoan]
  );

  const getLokyataScore = async () => {
    setLoaderMessage(
      "We're crunching the numbers. Please do not refresh or close your browser. It can take 2-3 minutes to go through bank details to confirm your income deposits. If you have not been redirected after this time, please call us at 801-477-1222 to help you with the next steps."
    );
    setLoading(true);
    const data = await getLokyataScoreData(pendingLoan?.loanId);
    setLoading(false);
    setLokyataScore(data);
    setTransition("lokyataScore");
  };

  const validateLokyataDetails = () => {
    if (lokyataScore) {
      if (lokyataScore.loanDenied === true) {
        setTransition("portal");
      } else if (lokyataScore.manualReview === true) {
        setTransition("manualReview");
      } else if (lokyataScore.scoreAvailable === true) {
        setLoaderMessage(
          "The next step of the approval process is to verify how you would like to receive funds and make payments."
        );
        setLoading(true);
        setTransition("fundingChecklist");
      } else {
        setTransition("loan");
      }
    }
  };

  const handleIframeClose = () => {
    setOpenIframe(false);
    if (bankVerificationAttemptCount > 2) {
      setLoaderMessage(
        "We were unable to connect your bank account to verify your income after three credential errors. Please upload documents and our support team will review them manually."
      );
      setLoading(true);
      setTransition("manualReview");
    } else {
      setTransition("bankVerification");
    }
  };

  return (
    <Container>
      <BackdropLoader loading={loading} message={loaderMessage ?? ""} />

      {error && (
        <Container>
          <ErrorComp message={error} onClose={() => setError("")} />
        </Container>
      )}
      {requestCodeDetails && (
        <>
          <Typography
            variant="body1"
            paragraph
            sx={{
              color: "#838588",
              fontSize: "14px",
              fontWeight: "400",
              mt: 4,
            }}
          >
            <strong>
              Please verify your bank to receive your direct deposit
            </strong>{" "}
            and enter the same credentials you use when logging in to your
            online financial institution. <br />
            <br /> You will have 3 attempts to verify your bank account, please
            ensure your credentials are up to date.
          </Typography>
        </>
      )}

      {requestCodeDetails?.iframeUrl && chirpLinkToken && (
        <div id="chirpLinkWidget"></div>
      )}
      {requestCodeDetails?.requestCode && (
        <>
          <Typography
            variant="body2"
            paragraph
            sx={{
              color: "#838588",
              fontSize: "14px",
              fontWeight: "400",
              mt: 8,
            }}
          >
            This system operates using 256‐bit encryption. Username and
            passwords are never displayed, viewed, or stored. <br />
            <br /> We use this system because it’s the safest & most convenient
            way for our customers to verify their banking information. This
            information is a necessary step in approving your loan request.
          </Typography>
        </>
      )}
    </Container>
  );
};

export default BankVerificationProcess;

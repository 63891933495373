import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, TextField, Button, Grid, Link } from "@mui/material";
import { Customer, LoanStatus } from "../../types/genericType";
import {
  getAdminCustomerDetails,
  getAdminCustomerLoanDetails,
  getReportLink,
} from "../../services/adminService";
import { redirect, useNavigate } from "react-router-dom";
import LoanStatusBox from "./LoanStatusBox";
import Loader from "../../components/loader/Loader";
import ErrorComp from "../../components/errorComp/ErrorComp";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ApiSuccess from "../../components/errorComp/ApiSuccess";
import GenericButton from "../../components/common/GenericButton";

const Admin = () => {
  const [customerID, setCustomerID] = useState<string>("");
  const [customerInfo, setCustomerInfo] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState<string>("");
  const [apiSuccess, setApiSuccess] = useState<string>("");
  const [loanStatus, setLoanStatus] = useState<LoanStatus>();
  const [reports, setReports] = useState({
    DecisionLogic: "No Data (opt-out)",
    DataX: "No Data (opt-out)",
    Clarity: "No Data (opt-out)",
  });
  const navigate = useNavigate();

  const fetchLoanStatus = async () => {
    try {
      const loanResponse: LoanStatus = await getAdminCustomerLoanDetails({
        customerId: customerID,
      });
      setLoanStatus(loanResponse);
      if (loanResponse.pending && loanResponse?.pending?.loanId) {
        fetchReport(loanResponse.pending.loanId);
      }
    } catch (err) {
      setApiError("Failed to fetch loan details");
    }
  };

  const handleSearch = async () => {
    if (customerID) {
      setLoading(true);
      setApiError("");
      try {
        const response: Customer = await getAdminCustomerDetails({
          customerId: customerID,
        });
        setCustomerInfo(response);
        fetchLoanStatus();
      } catch (err) {
        if (err instanceof Error && "response" in err) {
          setApiError((err as any).response?.data?.details);
        } else {
          setApiError("An unexpected error occurred");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchReport = async (loanId: number) => {
    Object.keys(reports).forEach(async (report: string) => {
      try {
        const data = await getReportLink({
          loanId: loanId,
          reportName: report,
        });
        if (data) {
          setReports((prev) => ({
            ...prev,
            [report]: data,
          }));
        }
      } catch (error) {
        setApiError("Failed to fetch reports");
      }
    });
  };

  const errorRef = useRef<HTMLDivElement>(null);
  const successRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (apiError && errorRef.current) {
      errorRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (apiSuccess && successRef.current) {
      successRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [apiError, apiSuccess]);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1160px",
        margin: { xs: "25px 0", sm: "25px auto" },
        padding: { xs: "0 10px", sm: "0 20px" },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              padding: { xs: "20px", sm: "30px", md: "50px 92px" },
              backgroundColor: "white",
              borderRadius: "8px",
              boxShadow: 1,
            }}
          >
            <Typography sx={{ fontSize: "14px", color: "#5a5a5b" }}>
              ADMIN
            </Typography>
            <Typography
              sx={{
                fontSize: "24px",
                color: "#00db8f",
                fontWeight: "500",
                mb: 2,
              }}
            >
              Customer Admin
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ mb: 2, color: "#838588", fontSize: "12px" }}
            >
              View customer loan and rewards status and approval of pending
              loans.
            </Typography>
            {apiSuccess && (
              <div ref={successRef}>
                <ApiSuccess
                  message={apiSuccess}
                  onClose={() => setApiSuccess("")}
                />
              </div>
            )}
            {apiError && (
              <div ref={errorRef}>
                <ErrorComp message={apiError} onClose={() => setApiError("")} />
              </div>
            )}
            <Typography
              sx={{
                fontSize: "12px",
                color: "#838588",
                fontWeight: "500",
                fontFamily: "AvenirNext-DemiBold",
                padding: 1,
              }}
            >
              Customer ID
            </Typography>
            <TextField
              value={customerID}
              onChange={(e) => setCustomerID(e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
            />
            <GenericButton
              label="SEARCH"
              onClick={handleSearch}
              additionalStyles={{ mt: 2, mb: 5 }}
            />
            {loading ? (
              <Loader />
            ) : (
              customerInfo && (
                <>
                  <Typography
                    variant="h6"
                    color="#838588"
                    fontWeight="bold"
                    fontFamily={"AvenirNext-Medium"}
                    fontSize="18px"
                  >
                    Customer Info 
                  </Typography>
                  <Box sx={{ mt: 2, mb: 4 }}>
                    <Typography
                      variant="body2"
                      sx={{ mt: 1, mb: 1, color: "#838588" }}
                    >
                      <strong>Name:</strong>
                      <br /> {customerInfo.firstName} {customerInfo?.lastName}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mt: 1, mb: 1, color: "#838588" }}
                    >
                      <strong>Address:</strong>
                      <br />
                      {customerInfo.address?.addressLine1}{" "}
                      {customerInfo.address?.addressLine2} <br />{" "}
                      {customerInfo.address?.city} {","}{" "}
                      {customerInfo.address?.state} {","}{" "}
                      {customerInfo.address?.zip}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mt: 1, mb: 1, color: "#838588" }}
                    >
                      <strong>DOB:</strong>
                      <br />{" "}
                      {new Date(customerInfo.dateOfBirth).toLocaleDateString(
                        "en-GB",
                        {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        }
                      )}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mt: 1, mb: 1, color: "#838588" }}
                    >
                      <strong>SSN:</strong> <br />
                      {customerInfo.socialSecurityNumber}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mt: 1, mb: 1, color: "#838588" }}
                    >
                      <strong>Email:</strong> <br />
                      {customerInfo.email}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mt: 1, mb: 1, color: "#838588" }}
                    >
                      <strong>Phone:</strong> <br />
                      {customerInfo.phoneNumber}
                    </Typography>
                  </Box>
                  {loanStatus && (
                    <LoanStatusBox
                      loanStatus={loanStatus}
                      customerId={customerID}
                      setApiError={setApiError}
                      setApiSuccess={setApiSuccess}
                    />
                  )}
                </>
              )
            )}
          </Box>
        </Grid>
        {customerInfo && (
          <Grid item xs={12} md={4}>
            <Box sx={{ padding: "24px" }}>
              {reports &&
                Object.entries(reports).map(([key, value]) => (
                  <Box key={key} sx={{ mb: 1 }}>
                    <Typography
                      variant="body2"
                      sx={{ mt: 1, mb: 1, color: "#838588", lineHeight: "2" }}
                    >
                      <strong>
                        {" "}
                        {`${key.charAt(0).toUpperCase() + key.slice(1)} Report`}
                      </strong>{" "}
                      <br />
                      {value.includes("http") ? (
                        <Link
                          href={value}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{
                            cursor: "pointer",
                            color: "#00db8f",
                            textDecoration: "none",
                            display: "flex",
                            "&:hover": {
                              textDecoration: "none",
                              color: "#00db8f",
                            },
                          }}
                          onClick={() => redirect(value)}
                        >
                          <EqualizerIcon sx={{ mr: 1 }} /> View Report{" "}
                        </Link>
                      ) : (
                        value
                      )}
                    </Typography>
                  </Box>
                ))}
              <Typography
                variant="body2"
                sx={{ mt: 1, color: "#838588", lineHeight: "2" }}
              >
                <strong>Rewards History</strong> <br />
              </Typography>
              <Button
                variant="text"
                sx={{
                  color: "#4caf50",
                  fontSize: "16px",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "rgba(25, 118, 210, 0.1)",
                  },
                }}
                onClick={() => {
                  navigate(`/admin/rewards/${customerID}`);
                }}
              >
                View
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Admin;

import React, { useEffect } from "react";
import PaidLoan from "./PaidLoan";
import ActiveLoan from "./ActiveLoan";
import DeniedLoan from "./DeniedLoan";
import PendingLoan from "./PendingLoan";
import WithdrawnLoan from "./WithdrawnLoan";
import Loader from "../../components/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCurrentLoanStatus,
  fetchCustomerDetails,
  fetchLoanStatus,
  fetchRewardsDetails,
} from "../../redux/apiSlice";
import { AppDispatch, RootState } from "../../redux/store";

const LoanPortal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    loanStatus,
    customerLoanStatus,
    currentLoanStatus,
    rewardsDetails,
    isLoading,
  } = useSelector((state: RootState) => state.api);

  useEffect(() => {
    dispatch(fetchLoanStatus());
    dispatch(fetchCustomerDetails());
    dispatch(fetchRewardsDetails());
    dispatch(fetchCurrentLoanStatus());
  }, [dispatch]);

  const renderLoanContent = () => {
    switch (loanStatus) {
      case "Active":
      case "Past Due":
        return (
          <ActiveLoan
            currentLoanStatus={currentLoanStatus}
            rewardsDetails={rewardsDetails}
          />
        );
      case "Paid in Full":
      case "Paid In Full Settlement":
        return (
          <PaidLoan
            currentLoanStatus={currentLoanStatus}
            customerLoanStatus={customerLoanStatus}
            rewardsDetails={rewardsDetails}
          />
        );
      case "Denied":
        return <DeniedLoan currentLoanStatus={currentLoanStatus} />;
      case "Withdrawn":
        return <WithdrawnLoan currentLoanStatus={currentLoanStatus} />;
      default:
        return <Loader message="" />;
    }
  };

  return isLoading ? (
    <Loader message="" />
  ) : (
    <>
      {customerLoanStatus?.pending && (
        <PendingLoan customerLoanStatus={customerLoanStatus} />
      )}
      {renderLoanContent()}
    </>
  );
};

export default LoanPortal;

import axios from "axios";
import { deleteToken, getUserDetails } from "./auth";
import { apiEndPoints } from "../constants/apiEndpoints";
import globalRouter from "./globalRouter";

const BASE_URL = window.location.origin;

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.url = `${BASE_URL}${config.url}`;
    const user = getUserDetails();
    const userDetails = user ? JSON.parse(user) : null;
    const token = userDetails?.authHeader;
    if (token) {
      config.headers["X-Authorization"] = token;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      originalRequest.url !== `${BASE_URL}${apiEndPoints.login}`
    ) {
      deleteToken();
      typeof globalRouter?.navigate === "function" &&
        globalRouter.navigate("/login");
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;

import React, { useEffect, useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import IconBtn from "../../components/IconBtn/IconBtn";
import UploadDocs from "./UploadDocs";
import { Doc, LoanDocuments } from "../../types/genericType";
import { uploadDocuments } from "../../services/loanPortalService";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { fetchLoanStatus } from "../../redux/apiSlice";
import { useNavigate } from "react-router-dom";
import BackdropLoader from "../loader/BackdropLoader";

const getLoanType = (customerLoanStatus: any) => {
  if (!customerLoanStatus) return "new";
  return customerLoanStatus.hasActiveLoan
    ? "uploan"
    : customerLoanStatus.hasCompletedLoan
    ? "reloan"
    : "new";
};

const LoanDocs = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { customerLoanStatus } = useSelector((state: RootState) => state.api);

  useEffect(() => {
    dispatch(fetchLoanStatus());
  }, [dispatch]);

  const [loanType, setLoanType] = useState<string>("");
  const [loanDocuments, setLoanDocuments] = useState<LoanDocuments>({
    proofOfIncome1: {} as Doc,
    proofOfIncome2: {} as Doc,
    photoId: {} as Doc,
    useExistingPhotoId: loanType === "uploan" || loanType === "reloan",
  });
  const [loanErrors, setLoanErrors] = useState<
    Partial<Record<keyof LoanDocuments, boolean>>
  >({});
  const [loading, setLoading] = useState(false);

  const handleDocumentChange = (
    change: any,
    controlId: keyof LoanDocuments
  ) => {
    setLoanDocuments((prev) => ({
      ...prev,
      [controlId]: change,
    }));

    if (change?.dataUrl) {
      setLoanErrors((prev) => ({
        ...prev,
        [controlId]: false,
      }));
    }
  };

  const handleSubmitDocuments = async () => {
    const errors: { [key in keyof LoanDocuments]?: boolean } = {};

    if (!loanDocuments.proofOfIncome1?.dataUrl) errors.proofOfIncome1 = true;
    if (!loanDocuments?.useExistingPhotoId && !loanDocuments.photoId?.dataUrl)
      errors.photoId = true;

    if (Object.keys(errors).length > 0) {
      setLoanErrors(errors);
      return;
    }
    const documents: Doc[] = [];
    if (loanDocuments.proofOfIncome1?.dataUrl) {
      documents.push(loanDocuments.proofOfIncome1);
    }
    if (loanDocuments.proofOfIncome2?.dataUrl) {
      documents.push(loanDocuments.proofOfIncome2);
    }
    if (loanDocuments.photoId?.dataUrl) {
      documents.push(loanDocuments.photoId);
    }

    setLoading(true);
    try {
      await uploadDocuments(documents);
      navigate("/Portal");
    } catch (error) {
      navigate("/sorry", {
        state: {
          error: {
            message:
              (error as any)?.response?.data?.message ||
              "An unexpected error occurred",
          },
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (customerLoanStatus) setLoanType(getLoanType(customerLoanStatus));
  }, [customerLoanStatus]);

  return (
    <Container>
      <Box sx={{ padding: "2rem", textAlign: "center" }}>
        <Typography
          variant="h5"
          component="h5"
          fontWeight="500"
          fontSize={26}
          color="#00db8f"
        >
          We've Received Your Application!*
        </Typography>
        <Typography
          variant="h5"
          component="h5"
          sx={{ color: "#838588", fontSize: "12px", fontWeight: "400", mt: 2 }}
        >
          Your application is submitted, but we can't approve your loan until
          you upload docs.
        </Typography>
      </Box>
      <UploadDocs
        loanDocuments={loanDocuments}
        loanErrors={loanErrors}
        requireSecondProofOfIncome={loanType === "new"}
        allowUseExistingPhotoId={
          loanDocuments?.useExistingPhotoId ||
          loanType === "uploan" ||
          loanType === "reloan"
        }
        onChange={handleDocumentChange}
      />
      <Box display="flex" justifyContent="center" mt={4}>
        <IconBtn
          icon={<LockIcon sx={{ fontSize: "18px", color: "#00db8f" }} />}
          handleBtnClick={() => {
            handleSubmitDocuments();
          }}
          title="SUBMIT"
          type="contained"
        />
      </Box>
      <BackdropLoader
        loading={loading}
        message="Thank you for submitting your documents. This process could take up to 5 minutes. If the screen doesn't update after that time, please try refreshing your browser. Thanks for waiting!"
      />
    </Container>
  );
};

export default LoanDocs;

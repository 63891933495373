import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  Grid,
  Divider,
  TextField,
  Button,
  Backdrop,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import IconButton from "../../components/IconBtn/IconBtn";
import { getBankAccounts, getBankCards } from "../../services/bankService";
import { BankAccountType, BankCardType } from "../../types/genericType";
import {
  getFundingChecklist,
  getPendingLoanApplicationStep,
  getPendingLoanDetails,
} from "../../services/loanPortalService";
import { generateAmountLabel } from "../../utils/utils";
import { EditOutlined } from "@mui/icons-material";
import Loader from "../../components/loader/Loader";
import ErrorComp from "../../components/errorComp/ErrorComp";
import FundingModal from "./FundingModal";
import { formatDateMinusOneDay } from "../../utils/dateUtils";

export interface FundingChecklistStateType {
  approvedLoanAmount: number;
  pendingLoan: any;
  signLoanAgreementUrl: string;
  bankCards: BankCardType[];
  pendingLoanApplicationStep: any;
  bankAccounts: BankAccountType[];
  fundingChecklist: any;
  dueDates: any[];
  nextBusinessDueDates: any[];
  dueDate?: string;
  dueDateCheck?: boolean;
  savedLoanFundingMethodBankOrCard: string;
  savedDefaultPaymentMethodBankOrCard: string;
  savedLoanFundingMethodBankOrCardLabel: string;
  savedDefaultPaymentMethodBankOrCardLabel: string;
  isEditable?: boolean;
}

const FundingChecklist = () => {
  const [loanAmount, setLoanAmount] = useState("0");
  const [editMode, setEditMode] = useState(false);
  const [fundingCheck, setFundingCheck] = useState("loanFundingMethod");
  const [modalOpen, setModalOpen] = useState(false);
  const [state, setState] = useState<FundingChecklistStateType>({
    approvedLoanAmount: 0,
    pendingLoan: {},
    signLoanAgreementUrl: "",
    bankCards: [],
    pendingLoanApplicationStep: {},
    bankAccounts: [],
    fundingChecklist: {},
    dueDates: [],
    nextBusinessDueDates: [],
    dueDate: "",
    dueDateCheck: false,
    savedLoanFundingMethodBankOrCard: "",
    savedDefaultPaymentMethodBankOrCard: "",
    savedLoanFundingMethodBankOrCardLabel: "",
    savedDefaultPaymentMethodBankOrCardLabel: "",
    isEditable: false,
  });
  const [loanPaymentMethodButtonText, setLoanPaymentMethodButtonText] =
    useState("");
  const [defaultPaymentMethodButtonText, setDefaultPaymentMethodButtonText] =
    useState("");
  const [loading, setLoading] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();

  const fetchBankAccounts = async () => {
    setLoading(true);
    try {
      const accounts = await getBankAccounts();
      setState((prevState) => ({ ...prevState, bankAccounts: accounts }));
      setLoading(false);
      setError("");
    } catch (error) {
      setError("Failed to fetch bank accounts");
      setLoading(false);
    }
  };

  const fetchBankCards = async () => {
    setLoading(true);
    try {
      const cards = await getBankCards();
      setState((prevState) => ({ ...prevState, bankCards: cards }));
      setLoading(false);
      setError("");
    } catch (error) {
      setError("Failed to fetch debit cards");
      setLoading(false);
    }
  };

  const fetchPendingLoan = async () => {
    setLoading(true);
    try {
      const loan = await getPendingLoanDetails();
      setState((prevState) => ({ ...prevState, pendingLoan: loan }));
      setLoading(false);
      setError("");
    } catch (error) {
      setError("Failed to fetch pending loan");
      setLoading(false);
    }
  };

  const fetchPendingLoanApplicationStep = async () => {
    setLoading(true);
    try {
      const data = await getPendingLoanApplicationStep(state.pendingLoan?.id);
      if (data) {
        setState((prevState) => ({
          ...prevState,
          pendingLoanApplicationStep: data,
          approvedLoanAmount: data?.approvedLoanAmount ?? 0,
        }));
      }
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch pending loan");
      setLoading(false);
    }
  };

  const fetchFundingChecklist = async () => {
    setLoaderMessage("Setting payment due dates");
    setLoading(true);
    try {
      const data = await getFundingChecklist(state.pendingLoan?.id);
      data && updateFundingChecklist(data);
      setLoading(false);
      setLoaderMessage("");
      setError("");
    } catch (error) {
      setError("Failed to fetch funding checklist");
      setLoading(false);
      setLoaderMessage("");
    }
  };

  const updateFundingChecklist = async (fundingChecklistData: any) => {
    const dueDates: { name: string; value: any }[] = [];
    if (fundingChecklistData.firstPaymentDueDate != null) {
      dueDates.push({
        name: "firstDueDate",
        value: fundingChecklistData.firstPaymentDueDate,
      });
    }
    if (
      fundingChecklistData.secondPaymentDueDate != null &&
      fundingChecklistData.secondPaymentDueDate !== "0001-01-01T00:00:00"
    ) {
      dueDates.push({
        name: "secondDueDate",
        value: fundingChecklistData.secondPaymentDueDate,
      });
    }
    const nextBusinessDueDates: { name: string; value: any }[] = [];
    if (fundingChecklistData.firstPaymentNextBusinessDayDueDate != null) {
      nextBusinessDueDates.push({
        name: "firstPaymentNextBusinessDayDueDate",
        value: fundingChecklistData.firstPaymentNextBusinessDayDueDate,
      });
    }
    if (
      fundingChecklistData.secondPaymentNextBusinessDayDueDate != null &&
      fundingChecklistData.secondPaymentNextBusinessDayDueDate !==
        "0001-01-01T00:00:00"
    ) {
      nextBusinessDueDates.push({
        name: "secondPaymentNextBusinessDayDueDate",
        value: fundingChecklistData.secondPaymentNextBusinessDayDueDate,
      });
    }

    setLoanFundingDetails(fundingChecklistData);
    setDefaultPaymentMethod(fundingChecklistData);

    setState((prevState) => ({
      ...prevState,
      dueDates: dueDates,
      nextBusinessDueDates: nextBusinessDueDates,
      fundingChecklist: fundingChecklistData,
    }));
    if (fundingChecklistData.selectedDueDate != null) {
      setState((prevState) => ({
        ...prevState,
        dueDate: fundingChecklistData.selectedDueDate,
        dueDateCheck: fundingChecklistData.movedToNextBusinessDay,
      }));
    }
  };

  const setLoanFundingDetails = (fundingChecklistData: any) => {
    if (fundingChecklistData.loanFundingMethod != null) {
      if (fundingChecklistData.loanFundingMethod.bankAccountId > 0) {
        const bankAccount = state.bankAccounts.find((obj) => {
          return (
            obj.id === fundingChecklistData.loanFundingMethod.bankAccountId
          );
        });
        if (bankAccount != null) {
          setState((prevState) => ({
            ...prevState,
            disableDefaultLoanPayment: false,
            disableReceiveFunds: false,
            savedLoanFundingMethodBankOrCard:
              bankAccount.bankAccountNumber.slice(-4),
            savedLoanFundingMethodBankOrCardLabel: "Bank Account",
          }));
        }
      } else if (fundingChecklistData.loanFundingMethod.bankCardId > 0) {
        const bankCard = state.bankCards.find((obj) => {
          return obj.id === fundingChecklistData.loanFundingMethod.bankCardId;
        });
        if (bankCard != null) {
          setState((prev) => ({
            ...prev,
            debitCard: true,
            bankAccount: false,
            disableDefaultLoanPayment: false,
            disableReceiveFunds: false,
            savedLoanFundingMethodBankOrCard: bankCard.cardNumber.slice(-4),
            savedLoanFundingMethodBankOrCardLabel: "Debit Card",
          }));
        }
      }
    }
  };

  const updateBtnText = () => {
    if (
      state.savedLoanFundingMethodBankOrCard &&
      state.savedLoanFundingMethodBankOrCard.length > 0
    ) {
      setLoanPaymentMethodButtonText(
        "Saved: " +
          state.savedLoanFundingMethodBankOrCardLabel +
          " ****" +
          state.savedLoanFundingMethodBankOrCard
      );
    }
    if (
      state.savedDefaultPaymentMethodBankOrCard &&
      state.savedDefaultPaymentMethodBankOrCard.length > 0
    ) {
      setDefaultPaymentMethodButtonText(
        "Saved: " +
          state.savedDefaultPaymentMethodBankOrCardLabel +
          " ****" +
          state.savedDefaultPaymentMethodBankOrCard
      );
    }
  };

  const setDefaultPaymentMethod = (fundingChecklistData: any) => {
    if (fundingChecklistData.defaultPaymentMethod != null) {
      if (fundingChecklistData.defaultPaymentMethod.bankAccountId > 0) {
        const bankAccount = state.bankAccounts.find((obj) => {
          return (
            obj.id === fundingChecklistData.defaultPaymentMethod?.bankAccountId
          );
        });
        if (bankAccount != null) {
          setState((prev) => ({
            ...prev,
            signLoanAgreementDisabled: false,
            disableDefaultLoanPayment: true,
            disableReceiveFunds: true,
            savedDefaultPaymentMethodBankOrCard:
              bankAccount.bankAccountNumber.slice(-4),
            savedDefaultPaymentMethodBankOrCardLabel: "Bank Account",
          }));
        }
      } else if (fundingChecklistData.defaultPaymentMethod.bankCardId > 0) {
        const bankCard = state.bankCards.find((obj) => {
          return (
            obj.id === fundingChecklistData.defaultPaymentMethod.bankCardId
          );
        });
        if (bankCard != null) {
          setState((prev) => ({
            ...prev,
            signLoanAgreementDisabled: false,
            disableDefaultLoanPayment: true,
            disableReceiveFunds: true,
            savedDefaultPaymentMethodBankOrCard: bankCard.cardNumber.slice(-4),
            savedDefaultPaymentMethodBankOrCardLabel: "Debit Card",
          }));
        }
      }
    }
  };

  useEffect(() => {
    updateBtnText();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.savedDefaultPaymentMethodBankOrCard,
    state.savedLoanFundingMethodBankOrCard,
    state.savedLoanFundingMethodBankOrCardLabel,
    state.savedDefaultPaymentMethodBankOrCardLabel,
  ]);

  useEffect(() => {
    fetchPendingLoan();
    fetchBankAccounts();
    fetchBankCards();
  }, []);

  useEffect(() => {
    if (state.pendingLoan?.id) {
      fetchPendingLoanApplicationStep();
      fetchFundingChecklist();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.pendingLoan]);

  const handleClose = () => {
    setModalOpen(false);
    fetchFundingChecklist();
  };

  return (
    <Container maxWidth="md" sx={{ marginTop: "1rem" }}>
      {error && (
        <Container>
          <ErrorComp message={error} onClose={() => setError("")} />
        </Container>
      )}
      <Box sx={{ textAlign: "center", marginBottom: "1rem" }}>
        <Typography variant="h5" sx={{ color: "#838588", fontWeight: "bold" }}>
          Congratulations !
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box sx={{ padding: "2rem" }}>
            <Typography
              variant="body2"
              sx={{
                color: "#838588",
                fontWeight: "500",
              }}
            >
              You have been approved up to ${state.approvedLoanAmount}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#838588",
                fontWeight: "500",
                mt: 2,
              }}
            >
              Loan Amount
            </Typography>
            <Box sx={{ display: "flex", mt: 1, mb: 2 }}>
              <TextField
                value={loanAmount}
                disabled={!editMode}
                size="small"
                sx={{
                  width: "100px",
                  mr: 2,
                  "& .MuiInputBase-root": {
                    height: "32px",
                    backgroundColor: editMode ? "inherit" : "#f0f0f0",
                    color: editMode ? "inherit" : "#838588",
                    padding: "0",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: editMode ? "rgba(0, 0, 0, 0.23)" : "#d0d0d0",
                    borderWidth: "1px",
                  },
                  "& .Mui-disabled": {
                    color: "#838588",
                  },
                  "& .MuiOutlinedInput-root": {
                    padding: "0px",
                  },
                }}
                onChange={(e) => setLoanAmount(e.target.value)}
              />
              {editMode && (
                <Button
                  variant="text"
                  size="small"
                  sx={{
                    color: "#838588",
                    textTransform: "none",
                    marginRight: 2,
                  }}
                  onClick={() => setEditMode(false)}
                >
                  Cancel
                </Button>
              )}
              <Button
                variant="contained"
                size="small"
                sx={{
                  color: "white",
                  backgroundColor: "#00db8f",
                  textTransform: "none",
                  ":hover": {
                    backgroundColor: "#00db8f",
                  },
                }}
                onClick={() => setEditMode(!editMode)}
              >
                {editMode ? "Save" : "Edit"}
              </Button>
            </Box>
          </Box>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ marginTop: "2rem", marginBottom: "2rem" }}
        />
        <Grid item xs={12} sm={5}>
          <Box sx={{ padding: "2rem" }}>
            <Typography
              variant="body2"
              sx={{
                color: "#333",
                fontWeight: "500",
              }}
            >
              Payments: US
              {generateAmountLabel(state.pendingLoan?.nextPaymentAmount)}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#333",
                fontWeight: "500",
              }}
            >
              (For loan amount of US
              {generateAmountLabel(state.pendingLoan?.originalAmount)})
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#333",
                fontWeight: "500",
                mt: 4,
              }}
            >
              Payments Frequency: {state.pendingLoan?.payFrequency}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          border: "1px solid lightgray",
          borderRadius: "10px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          marginTop: "2rem",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: "#838588",
            fontWeight: "500",
            fontSize: "24px",
            mt: 2,
          }}
        >
          Funding Checklist
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: "#00db8f",
              textTransform: "none",
              width: "245px",
              mt: 2,
              mr: 2,
              ":hover": {
                backgroundColor: "#00db8f",
              },
              ":disabled": {
                backgroundColor: "rgba(0, 219, 143, .65)",
                color: "white",
              },
            }}
            disabled={state?.savedLoanFundingMethodBankOrCard !== ""}
            onClick={() => {
              setFundingCheck("loanFundingMethod");
              setModalOpen(true);
            }}
          >
            VERIFY, Loan Funding Method
            <br></br> {loanPaymentMethodButtonText}
          </Button>
          {state?.savedLoanFundingMethodBankOrCard !== "" &&
            state?.savedDefaultPaymentMethodBankOrCard !== "" && (
              <Button
                onClick={() => {
                  setState((prev) => ({ ...prev, isEditable: true }));
                  setFundingCheck("loanFundingMethod");
                  setModalOpen(true);
                }}
              >
                <EditOutlined sx={{ color: "#00db8f" }} />
              </Button>
            )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: "#00db8f",
              textTransform: "none",
              mt: 3,
              mb: 4,
              mr: 2,
              ":hover": {
                backgroundColor: "#00db8f",
              },
              ":disabled": {
                backgroundColor: "rgba(0, 219, 143, .65)",
                color: "white",
              },
            }}
            disabled={
              state?.savedLoanFundingMethodBankOrCard === "" ||
              state?.savedDefaultPaymentMethodBankOrCard !== ""
            }
            onClick={() => {
              setFundingCheck("defaultFundingMethod");
              setModalOpen(true);
            }}
          >
            Verify, Default Payment Method <br />{" "}
            {defaultPaymentMethodButtonText !== "" &&
              `${defaultPaymentMethodButtonText}`}{" "}
            <br />
            {state.fundingChecklist &&
              state.fundingChecklist.selectedDueDate != null &&
              `First Payment Date: ${formatDateMinusOneDay(state.fundingChecklist.selectedDueDate)}`}
          </Button>
          {state?.savedLoanFundingMethodBankOrCard !== "" &&
            state?.savedDefaultPaymentMethodBankOrCard !== "" && (
              <Button
                onClick={() => {
                  setState((prev) => ({ ...prev, isEditable: true }));
                  setFundingCheck("defaultFundingMethod");
                  setModalOpen(true);
                }}
              >
                <EditOutlined sx={{ color: "#00db8f" }} />
              </Button>
            )}
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" mt={4}>
        <IconButton
          icon={
            <LockIcon
              sx={{
                fontSize: "18px",
                color: "#00db8f",
              }}
            />
          }
          sx={{
            backgroundColor: "#00584f",
            ":hover": {
              backgroundColor: "#00584f",
            },
            ":disabled": {
              backgroundColor: "rgba(0, 88, 79, .65)",
              color: "white",
            },
            height: "60px",
            padding: "15px 80px",
          }}
          handleBtnClick={() => {
            navigate("/Portal/sign", {
              state: {
                signAggreement: true,
              },
            });
          }}
          disabled={
            state?.savedLoanFundingMethodBankOrCard === "" ||
            state?.savedDefaultPaymentMethodBankOrCard === ""
          }
          title="SIGN LOAN AGREEMENT"
          type="contained"
        />
      </Box>
      <FundingModal
        open={modalOpen}
        handleClose={handleClose}
        fundingCheck={fundingCheck}
        data={state}
        setData={setState}
      />
      <Backdrop
        open={loading}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          zIndex: 9999,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "800px",
          }}
        >
          <Loader message={loaderMessage} />
        </Box>
      </Backdrop>
    </Container>
  );
};

export default FundingChecklist;

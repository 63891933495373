import { Modal, Paper, Box, Button, Typography } from "@mui/material";
import React from "react";
import { ModalProps } from "../../types/genericType";

const HelpBankVerification = (props: ModalProps) => {
  const { open, handleClose } = props;

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper
        sx={{
          maxWidth: "700px",
          height: "90vh",
          overflowY: "auto",
          padding: 0,
          margin: "80px auto !important",
          borderRadius: 0,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#D3D3D3",
            }}
            onClick={handleClose}
          >
            X
          </Button>
        </Box>
        <Typography
          sx={{
            fontSize: { xs: "20px", sm: "24px" },
            fontWeight: "400",
            textAlign: "center",
            color: "#4d4d4d",
            marginTop: "40px",
          }}
          gutterBottom
        >
          Why do we need this?
        </Typography>
        <Box
          sx={{
            color: "#838588",
            padding: { xs: "30px 30px", sm: "30px 50px" },
            fontSize: { xs: "10px", sm: "14px" },
          }}
        >
          <p>
            Bank account verification is a fast, secure, and routine fraud
            prevention measure used when applying for financing. As part of our
            standard underwriting process, we ask you to access the bank account
            in which you receive the majority of your income. Once you enter
            your User ID and Password, we will receive an encrypted, read-only
            screenshot of your recent bank transactions. We DO NOT store your
            username or password.
          </p>
        </Box>
      </Paper>
    </Modal>
  );
};

export default HelpBankVerification;

import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import Loader from "../../components/loader/Loader";
import ApiSuccess from "../../components/errorComp/ApiSuccess";
import ErrorComp from "../../components/errorComp/ErrorComp";
import { updateCustomerDetails } from "../../services/customerService";

const MilitaryInfo = () => {
  const customer = JSON.parse(
    sessionStorage.getItem("customerDetails") || "{}"
  );
  const [customerData, setCustomerData] = useState(customer);
  const [originalCustomerData] = useState(customer);
  const [isMilitary, setIsMilitary] = useState<boolean>(customer?.isMilitary);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [apiSuccess, setApiSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const updateCustomerData = async () => {
    try {
      const response = await updateCustomerDetails({
        ...customer,
        isMilitary,
      });

      if (response && response?.status === 200) {
        const updatedCustomerData = {
          ...customerData,
          isMilitary,
        };
        sessionStorage.setItem(
          "customerDetails",
          JSON.stringify(updatedCustomerData)
        );

        console.log("Customer data updated successfully.");
        return true;
      } else {
        console.error("Failed to update customer data:", response);
        return false;
      }
    } catch (error) {
      console.error("Failed to update customer data:", error);
      return false;
    }
  };

  const handleSave = async () => {
    setLoading(true);
    const isUpdated = await updateCustomerData();
    setLoading(false);
    if (isUpdated) {
      setApiSuccess(true);
      setEditMode(false);
    } else {
      console.error("Failed to save customer data. Please try again.");
    }
  };

  const handleCancel = () => {
    setCustomerData(originalCustomerData);
    setIsMilitary(originalCustomerData?.isMilitary);
    setEditMode(false);
    setError("");
  };

  return loading ? (
    <Loader />
  ) : (
    <Container>
      {apiSuccess && (
        <ApiSuccess
          message="Military Info updated successfully!"
          onClose={() => setApiSuccess(false)}
        />
      )}
      {error && <ErrorComp message={error} onClose={() => setError("")} />}
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6" sx={{ mb: 2, color: "#838588" }}>
            Military Information
          </Typography>
          {!editMode && (
            <Button
              variant="text"
              sx={{
                color: "#00db8f",
                textTransform: "none",
                alignItems: "center",
                mt: 4,
                mb: 2,
              }}
              onClick={() => setEditMode(true)}
            >
              <ModeEditOutlineIcon
                sx={{ fontSize: "18px", color: "#00db8f" }}
              />
              Edit
            </Button>
          )}
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!isMilitary}
                  onChange={(e) => setIsMilitary(!e.target.checked)}
                  disabled={!editMode}
                />
              }
              label={
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    textAlign: "start",
                    color: "#838588",
                  }}
                >
                  NO, I am not a regular or reserve member of the Army, Navy,
                  Marine Corps, Air Force, or Coast Guard, serving on active
                  duty under a call or order that does not specify a period of
                  30 days or fewer (or a dependent of such a member).
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
        {editMode && (
          <>
            <Button
              variant="text"
              sx={{
                color: "#838588",
                textTransform: "none",
                marginRight: 2,
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                color: "white",
                backgroundColor: "#00db8f",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#00db8f",
                },
              }}
              onClick={handleSave}
            >
              Save
            </Button>
          </>
        )}
      </Box>
    </Container>
  );
};

export default MilitaryInfo;

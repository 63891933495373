import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import AddressForm from "../addressForm/AddressForm";
import { Address } from "../../interfaces";
import TextInput from "../common/TextInput";
import Constants from "../../constants/constant";
import Dropdown from "../common/Dropdown";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import ErrorComp from "../errorComp/ErrorComp";
import Loader from "../loader/Loader";
import ApiSuccess from "../errorComp/ApiSuccess";
import { updateCustomerDetails } from "../../services/customerService";
import { formatDateMinusOneDay } from "../../utils/dateUtils";

const BasicProfileInfo = () => {
  const customer = JSON.parse(
    sessionStorage.getItem("customerDetails") || "{}"
  );

  const [customerData, setCustomerData] = useState(customer);
  const [originalCustomerData] = useState(customer);
  const [address, setAddress] = useState<Address>(customer?.address || {});
  const [loading, setLoading] = useState<boolean>(false);
  const [apiSuccess, setApiSuccess] = useState<boolean>(false);
  const [error, setError] = useState("");
  const [editMode, setEditMode] = useState<boolean>(false);

  const handleCustomerChange = (field: string, value: string) => {
    setCustomerData({ ...customerData, [field]: value });
  };

  const handleAddressChange = ({
    id,
    value,
  }: {
    id: string;
    value: string;
  }) => {
    setAddress({ ...address, [id]: value });
  };

  const updateCustomerData = async () => {
    try {
      const response = await updateCustomerDetails({
        ...customer,
        phoneNumber: customerData?.phoneNumber,
        driversLicenseNumber: customerData?.driversLicenseNumber,
        driversLicenseState: customerData?.driversLicenseState,
        email: customerData?.email,
        address: address,
      });

      if (response && response?.status === 200) {
        const updatedCustomerData = {
          ...customerData,
          address,
        };
        sessionStorage.setItem(
          "customerDetails",
          JSON.stringify(updatedCustomerData)
        );
        return true;
      } else {
        console.error("Failed to update customer data:", response);
        return false;
      }
    } catch (error) {
      console.error("Failed to update customer data:", error);
      return false;
    }
  };

  const handleSave = async () => {
    setLoading(true);
    const isUpdated = await updateCustomerData();
    setLoading(false);
    if (isUpdated) {
      setApiSuccess(true);
      setEditMode(false);
    } else {
      console.error("Failed to save customer data. Please try again.");
    }
  };

  const handleCancel = () => {
    setCustomerData(originalCustomerData);
    setAddress(customer?.address || {});
    setEditMode(false);
  };

  return loading ? (
    <Loader />
  ) : (
    <Container>
      {apiSuccess && (
        <ApiSuccess
          message="Saved customer info"
          onClose={() => setApiSuccess(false)}
        />
      )}
      {error && (
        <ErrorComp
          message={
            "Something went wrong while updating customer info. Please try again!!"
          }
          onClose={() => setError("")}
        />
      )}
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6" sx={{ mb: 2, color: "#838588" }}>
            Basic Information
          </Typography>
          {!editMode && (
            <Button
              variant="text"
              sx={{
                color: "#00db8f",
                textTransform: "none",
                alignItems: "center",
                mt: 4,
                mb: 2,
              }}
              onClick={() => setEditMode(!editMode)}
            >
              <ModeEditOutlineIcon
                sx={{ fontSize: "18px", color: "#00db8f" }}
              />
              Edit
            </Button>
          )}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInput label="Customer ID" value={customerData?.id} />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label="Name"
              value={`${customerData?.firstName} ${customerData?.lastName}`}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label="Date of Birth"
              value={
                customerData?.dateOfBirth &&
                formatDateMinusOneDay(customerData?.dateOfBirth)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput label="SSN" value={customerData.socialSecurityNumber} />
          </Grid>

          {!editMode ? (
            <Grid item xs={12}>
              <TextInput
                label="Driver's License"
                value={`${customerData?.driversLicenseNumber} ${customerData.driversLicenseState}`}
                readOnly
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={6}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "600",
                    paddingLeft: "8px",
                    marginBottom: "5px",
                    color: "#838588",
                  }}
                >
                  Driving License
                </Typography>
                <TextField
                  variant="outlined"
                  value={customerData.driversLicenseNumber}
                  onChange={(e) =>
                    handleCustomerChange("driversLicenseNumber", e.target.value)
                  }
                  fullWidth
                  size="small"
                  placeholder="Enter Driving License"
                />
              </Grid>
              <Grid item xs={6}>
                <Dropdown
                  id="state"
                  label=""
                  value={customerData.driversLicenseState}
                  options={Constants.stateOptions.map((option) => ({
                    value: option.value,
                    label: option.name,
                  }))}
                  onChange={(val: any) =>
                    handleCustomerChange("driversLicenseState", val)
                  }
                  required
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      <Box mt={2}>
        <Grid container spacing={2}>
          {editMode ? (
            <AddressForm
              value={address}
              onChange={handleAddressChange}
              required
              addressRequired
            />
          ) : (
            <Grid item xs={12}>
              <TextInput
                label="Address"
                value={`${address?.addressLine1 || ""} ${address?.city || ""} ${
                  address?.state || ""
                } ${address?.zip || ""}`}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInput
              label="Phone"
              key="phone"
              value={customerData.phoneNumber}
              onChange={(e) => handleCustomerChange("phone", e.target.value)}
              readOnly={!editMode}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label="Email"
              key="email"
              value={customerData.email}
              onChange={(e) => handleCustomerChange("email", e.target.value)}
              readOnly={!editMode}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
        {editMode && (
          <>
            <Button
              variant="text"
              sx={{
                color: "#838588",
                textTransform: "none",
                marginRight: 2,
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                color: "white",
                backgroundColor: "#00db8f",
                textTransform: "none",
                "&:hover": {
                  color: "white",
                  backgroundColor: "#00db8f",
                },
              }}
              onClick={handleSave}
            >
              Save
            </Button>
          </>
        )}
      </Box>
    </Container>
  );
};

export default BasicProfileInfo;

import { Modal, Paper, Box, Button, Typography } from "@mui/material";
import React from "react";
import { ModalProps } from "../../types/genericType";
import Constants from "../../constants/constant";

const ConsentEmailSMSModal = (props: ModalProps) => {
  const { open, handleClose } = props;
  const info = Constants.infoLiftCredit;

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper
        sx={{
          maxWidth: "700px",
          height: "90vh",
          overflowY: "auto",
          padding: 0,
          margin: "80px auto !important",
          borderRadius: 0,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#D3D3D3",
            }}
            onClick={handleClose}
          >
            X
          </Button>
        </Box>
        <Typography
          sx={{
            fontSize: { xs: "20px", sm: "24px" },
            fontWeight: "400",
            textAlign: "center",
            color: "#4d4d4d",
            marginTop: "40px",
          }}
          gutterBottom
        >
          Consent to Automated
          <br /> Marketing Emails and Text Messages
        </Typography>
        <Box
          sx={{
            color: "#838588",
            padding: { xs: "30px 30px", sm: "30px 50px" },
            fontSize: { xs: "10px", sm: "14px" },
          }}
        >
          <p>
            Consent to Automated Marketing Emails and Text Messages. By checking
            the box, you authorize {info.companyName} (or its agents), to send
            marketing text messages and emails to the telephone number you
            provided above using an automatic system on a recurring basis.
            Signing this consent is not a condition of purchasing property,
            goods or services through us. If you do not wish to receive sales or
            marketing calls or texts from us, you should uncheck the "I AGREE"
            box below. You understand that any messages we leave for you may be
            accessed by anyone with access to your voicemail or text messages.
            You understand that your mobile phone service provider may charge
            you fees for calls that we make or text that we send to you, and you
            agree that we will have no liability for the cost of any such calls
            or texts. At any time, you may withdraw your consent to receive
            marketing calls and text messages by calling us at {info.phone},
            emailing us at {info.email}, or by other reasonable means.
            Alternatively, to stop marketing text messages, simply reply "STOP"
            to any marketing text message that we send you.
          </p>

          <p>
            I agree (1) You consent to our providing the consent to automated
            marketing calls and text disclosure (above) to you electronically,
            (2) you consent to our sending marketing text messages and emails to
            you using the phone number you provided and (3) you acknowledge that
            you saved a copy of this agreement.
          </p>
        </Box>
      </Paper>
    </Modal>
  );
};

export default ConsentEmailSMSModal;

import React, { useEffect, useState, useCallback } from "react";
import LoanAgreement from "./LoanAgreement";
import { getLoanAgreement } from "../../services/loanPortalService";
import { Container } from "@mui/material";
import ErrorComp from "../../components/errorComp/ErrorComp";
import Loader from "../../components/loader/Loader";

interface LoanAgreementViewerProps {
  isAdditionalAgreement?: boolean;
}

const LoanAgreementViewer: React.FC<LoanAgreementViewerProps> = ({
  isAdditionalAgreement = false,
}) => {
  const [loanAgreementPdf, setLoanAgreementPdf] = useState<string | null>(null);
  const [loanAgreementHtml, setLoanAgreementHtml] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchAgreement = useCallback(async (format: "html" | "pdf") => {
    setLoading(true);
    try {
      const data = await getLoanAgreement(format, isAdditionalAgreement);
      format === "html"
        ? setLoanAgreementHtml(data)
        : setLoanAgreementPdf(data);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch loan agreement");
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchAgreement("html");
  }, [fetchAgreement]);

  return (
    <>
      {error && (
        <Container>
          <ErrorComp message={error} onClose={() => setError("")} />
        </Container>
      )}
      {loading ? (
        <Container>
          <Loader />
        </Container>
      ) : (
        <LoanAgreement
          agreementPdf={loanAgreementPdf}
          agreementHtml={loanAgreementHtml}
          onRequestAgreement={fetchAgreement}
          isAdditionalAgreement={isAdditionalAgreement}
        />
      )}
    </>
  );
};

export default LoanAgreementViewer;

import React, { useState } from "react";
import { Box, Container, Grid, Typography, Button } from "@mui/material";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import Loader from "../../components/loader/Loader";
import ApiSuccess from "../../components/errorComp/ApiSuccess";
import ErrorComp from "../../components/errorComp/ErrorComp";
import TextInput from "../common/TextInput";
import { updatePassword } from "../../services/authService";

const PasswordInfo = () => {
  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [apiSuccess, setApiSuccess] = useState<boolean>(false);
  const [errors, setErrors] = useState({
    apiError: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handlePasswordChange = (field: string, value: string) => {
    setPasswordData({ ...passwordData, [field]: value });
    setErrors({ ...errors, [field]: "" });
  };

  const handleSave = async () => {
    const newErrors = {
      apiError: "",
      currentPassword: passwordData.currentPassword ? "" : "Required field",
      newPassword: passwordData.newPassword ? "" : "Required field",
      confirmPassword: passwordData.confirmPassword ? "" : "Required field",
    };

    if (
      Object.values(newErrors).some((error) => error) ||
      passwordData.newPassword !== passwordData.confirmPassword
    ) {
      if (passwordData.newPassword !== passwordData.confirmPassword) {
        newErrors.newPassword = " ";
        newErrors.confirmPassword = "Password mismatch";
      }
      setErrors(newErrors);
      return;
    }

    setLoading(true);

    try {
      await updatePassword({
        currentPassword: passwordData.currentPassword,
        newPassword: passwordData.newPassword,
      });
      setLoading(false);
      setApiSuccess(true);
      setEditMode(false);
    } catch (error) {
      setLoading(false);
      setErrors({
        ...newErrors,
        apiError:
          "Something went wrong while updating password. Please try again.",
      });
    }
  };

  const handleCancel = () => {
    setPasswordData({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setErrors({
      apiError: "",
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setEditMode(false);
  };

  return loading ? (
    <Loader />
  ) : (
    <Container>
      {apiSuccess && (
        <ApiSuccess
          message="Updated Password"
          onClose={() => setApiSuccess(false)}
        />
      )}
      {errors?.apiError && (
        <ErrorComp
          message="Something went wrong while updating password. Please try again."
          onClose={() =>
            setErrors({
              ...errors,
              apiError: "",
            })
          }
        />
      )}
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6" sx={{ mb: 2, color: "#838588" }}>
            Password Information
          </Typography>
          {!editMode && (
            <Button
              variant="text"
              sx={{
                color: "#00db8f",
                textTransform: "none",
                alignItems: "center",
                mt: 4,
                mb: 2,
              }}
              onClick={() => setEditMode(true)}
            >
              <ModeEditOutlineIcon
                sx={{ fontSize: "18px", color: "#00db8f" }}
              />
              Edit
            </Button>
          )}
        </Box>
        {editMode ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextInput
                label="Current Password"
                key="currentPassword"
                value={passwordData.currentPassword}
                onChange={(e) =>
                  handlePasswordChange("currentPassword", e.target.value)
                }
                readOnly={!editMode}
                placeholder="Enter Current Password"
                required
                error={!!errors.currentPassword}
                helperText={errors.currentPassword}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="New Password"
                key="newPassword"
                value={passwordData.newPassword}
                onChange={(e) =>
                  handlePasswordChange("newPassword", e.target.value)
                }
                readOnly={!editMode}
                placeholder="Enter New Password"
                required
                error={!!errors.newPassword}
                helperText={passwordData?.newPassword ? "" : errors.newPassword}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="Confirm Password"
                key="confirmPassword"
                value={passwordData.confirmPassword}
                onChange={(e) =>
                  handlePasswordChange("confirmPassword", e.target.value)
                }
                readOnly={!editMode}
                placeholder="Confirm New Password"
                required
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextInput label="Password" value="**********" />
            </Grid>
          </Grid>
        )}
      </Box>
      <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
        {editMode && (
          <>
            <Button
              variant="text"
              sx={{
                color: "#838588",
                textTransform: "none",
                marginRight: 2,
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                color: "white",
                backgroundColor: "#00db8f",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#00db8f",
                },
              }}
              onClick={handleSave}
            >
              Save
            </Button>
          </>
        )}
      </Box>
    </Container>
  );
};

export default PasswordInfo;

import React, { useState } from "react";
import { Typography } from "@mui/material";
import { LoanApprovalType, LoanStatus } from "../../types/genericType";
import ProcessApproval from "./ProcessApproval";
import SignLoanRequest from "./SignLoanRequest";

type Props = {
  loanStatus: LoanStatus;
  customerId: string;
  setApiError: Function;
  setApiSuccess: Function;
};

const LoanStatusBox = (props: Props) => {
  const { loanStatus, customerId, setApiError, setApiSuccess } = props;
  const [loanApproval, setLoanApproval] = useState<LoanApprovalType>();

  const getLoanStatus = () => {
    if (loanStatus) {
      if (loanStatus.pending) {
        if (
          loanStatus.pending.approvalStatus === "Pending Final Approval" ||
          loanStatus.pending.approvalStatus === "Pending"
        )
          return "Application submitted; Pending Final approval";
        else if (loanStatus.pending.approvalStatus === "Approved")
          return "Text/Email approval sent; Waiting for customer signature";
        else if (loanStatus.pending.approvalStatus === "Signed")
          return "Loan already signed";
      } else {
        return "Customer has no pending loan";
      }
    } else return null;
  };

  return (
    <>
      <Typography variant="h6" color="#838588" fontWeight="bold" fontSize={16}>
        Loan Status
      </Typography>
      {!loanStatus?.hasPendingLoan ? (
        <Typography variant="body2" sx={{ mt: 2, mb: 4, color: "#838588" }}>
          Customer has no pending loan
        </Typography>
      ) : (
        <>
          <Typography
            variant="body2"
            sx={{
              mt: 2,
              ml: 1,
              color: "#838588",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
            Pending Loan
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "#838588",
              fontSize: "12px",
            }}
          >
            {loanStatus.pending.loanId}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              ml: 1,
              mt: "4px",
              color: "#838588",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
            Amount
          </Typography>
          <Typography
            variant="body2"
            sx={{
              lineHeight: "1.5",
              color: "#838588",
              fontSize: "12px",
            }}
          >
            {`US$${loanStatus.pending.amount.toFixed(2)}`}
            <br />
            {getLoanStatus()}
          </Typography>
          {loanApproval && (
            <Typography
              variant="body2"
              sx={{
                mt: 2,
                ml: 1,
                color: "#838588",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              <div>
                <b>Decision: </b>
                {loanApproval.decision}
              </div>
              <div>
                <b>Max Approved Loan Amount: US${loanApproval.maxLoanAmount}</b>
              </div>
              <div>
                (Verified Income Amount: US${loanApproval.verifiedIncomeAmount})
              </div>
              {loanApproval.basis && (
                <div>
                  <i>Basis for Decision:</i> {loanApproval.basis}{" "}
                </div>
              )}
              {loanApproval.reasons && (
                <div>
                  <i>Reason(s):</i>{" "}
                  <ul>
                    {loanApproval.reasons.map((reason: string) => (
                      <li>{reason}</li>
                    ))}
                  </ul>
                </div>
              )}
            </Typography>
          )}
          <ProcessApproval
            customerId={customerId}
            setApiError={setApiError}
            setLoanApproval={setLoanApproval}
          />
          <SignLoanRequest
            customerId={customerId}
            setApiError={setApiError}
            setApiSuccess={setApiSuccess}
          />
           
        </>
      )}
    </>
  );
};

export default LoanStatusBox;

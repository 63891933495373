import { Box } from "@mui/material";
import TopBar from "../topBar/TopBar";
import { Outlet } from "react-router-dom";
import Footer from "../footer/Footer";
import { useContext, useState } from "react";
import { AuthContext } from "../../core/authContext";
import MenuComponent from "../menuComponent/MenuComponent";
import MobileMenu from "../menuComponent/MobileMenu";

const MainLayout = () => {
  const userDetails = useContext(AuthContext);
  const isAdminView = userDetails?.user?.role === "Admin";
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Box
      className="main-layout-container"
      sx={{ backgroundColor: "#f4f4f4", minHeight: "100vh" }}
    >
      <TopBar handleMenuToggle={handleMenuToggle} />
      {userDetails?.isLoggedIn && !isAdminView && (
        <>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <MenuComponent />
          </Box>
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <MobileMenu
              menuOpen={menuOpen}
              handleMenuToggle={handleMenuToggle}
            />
          </Box>
        </>
      )}
      <Box
        component="main"
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: userDetails?.isLoggedIn
            ? userDetails?.user?.role === "Admin"
              ? "64px"
              : { xs: 0, sm: "20px" }
            : { xs: "36px", sm: "64px" },
          padding: { xs: "0", sm: "0 32px", md: "0 64px" },
        }}
      >
        {isAdminView ? (
          <Outlet />
        ) : (
          <Box
            sx={{
              width: "100%",
              maxWidth: "950px",
              margin: {
                xs: "10px 0 0 0",
                sm: "25px 20px 0 20px",
                md: "20px 100px",
              },
              padding: { xs: "20px", sm: "30px", md: "50px 8%" },
              backgroundColor: "white",
            }}
          >
            <Outlet />
          </Box>
        )}
      </Box>
      <Footer />
    </Box>
  );
};

export default MainLayout;

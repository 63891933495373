import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import IconBtn from "../IconBtn/IconBtn";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import { forgotPassword } from "../../services/authService";
import ErrorComp from "../errorComp/ErrorComp";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [apiError, setApiError] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  const isValidEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleResetPassword = async () => {
    if (!email) {
      setError("Required field");
    } else if (!isValidEmail(email)) {
      setError("Invalid email");
    }

    if (email && isValidEmail(email)) {
      try {
        const data = await forgotPassword(email);
        if (data) {
          setSuccess(true);
        }
      } catch (error) {
        setApiError(
          (error as Error)?.message || "An unexpected error occurred"
        );
      }
    }
  };

  useEffect(() => {
    if (email) {
      setError("");
    }
  }, [email]);

  return (
    <Container>
      {apiError && (
        <ErrorComp message={apiError} onClose={() => setApiError("")} />
      )}
      <Box sx={{ textAlign: "center", marginBottom: "2rem" }}>
        <Typography
          variant="subtitle1"
          sx={{ color: "#00db8f", fontSize: "24px", fontWeight: 500 }}
        >
          {success ? "Password Reset Requested" : "Portal Sign In Help"}
        </Typography>
        <Typography variant="body2" sx={{ color: "#838588" }}>
          {success
            ? "Please check your email"
            : "Get Username and password help"}
        </Typography>
      </Box>
      {success ? (
        <Typography variant="body2" sx={{ color: "#838588", mb: 20 }}>
          An email has been sent to your email address with a link to reset your
          password. Please check your email and follow the instructions
          provided.
        </Typography>
      ) : (
        <>
          <>
            <Typography
              variant="body1"
              sx={{
                color: "#838588",
                fontWeight: "500",
                marginBottom: "10px",
                mt: 1,
              }}
            >
              Having trouble signing in?
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              <DoneIcon sx={{ color: "#00db8f", marginRight: "10px" }} />
              <Box>
                <Typography variant="body2" sx={{ color: "#838588" }}>
                  Did you forget your username?
                </Typography>
                <Typography variant="body2" sx={{ color: "#838588" }}>
                  Hint: It is the <i>email address</i> you provided when you
                  signed up.
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "15px",
                mt: 3,
              }}
            >
              <DoneIcon sx={{ color: "#00db8f", marginRight: "10px" }} />
              <Box>
                <Typography variant="body2" sx={{ color: "#838588" }}>
                  Did you forget your password?
                </Typography>
                <Typography variant="body2" sx={{ color: "#838588" }}>
                  Enter your username below, and we will email you a password
                  reminder:
                </Typography>
              </Box>
            </Box>
          </>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                Email
              </Typography>
              <TextField
                variant="outlined"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                fullWidth
                size="small"
                placeholder="Enter Your Email Address"
                error={!!error}
                helperText={error}
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" mt={4}>
            <IconBtn
              icon={<LockIcon sx={{ fontSize: "18px", color: "#00db8f" }} />}
              handleBtnClick={handleResetPassword}
              title="Request Password"
              type="contained"
            />
          </Box>
        </>
      )}
      <Box display="flex" justifyContent="center" mt={2}>
        <Button
          variant="text"
          onClick={() => {
            navigate("/login");
          }}
          sx={{
            color: "#00db8f",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          Return to Login
        </Button>
      </Box>
    </Container>
  );
};

export default ForgotPassword;

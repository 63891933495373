import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Grid } from "@mui/material";
import { Close, Upload } from "@mui/icons-material";
import TextInput from "./TextInput";
import { LoanDocuments } from "../../types/genericType";

type FileUploadFieldProps = {
  hint?: string;
  id: keyof LoanDocuments;
  required?: boolean;
  documentType?: string;
  showPreview?: boolean;
  canAdd?: boolean;
  canDelete?: boolean;
  columns?: string;
  onAdd?: () => void;
  onDelete?: (id: string) => void;
  value?: {
    dataUrl?: string;
    filename?: string;
    filetype?: string;
  };
  className?: string;
  label?: string;
  onChange?: (change: any, controlId: keyof LoanDocuments) => void;
  error?: boolean;
};

const FileUploadField: React.FC<FileUploadFieldProps> = ({
  id,
  documentType,
  showPreview = false,
  canAdd,
  canDelete,
  onAdd,
  onDelete,
  value,
  className,
  onChange,
  error,
}) => {
  const [thumbDataUrl, setThumbDataUrl] = useState<string | null>(null);
  const [filename, setFilename] = useState<string | null>(
    value?.filename || null
  );
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (value?.dataUrl && value?.filetype !== "pdf") {
      resizeImage(value.dataUrl, setThumbDataUrl);
    }
  }, [value]);

  const resizeImage = (
    dataUrl: string,
    callback: (url: string | null) => void
  ) => {
    const maxWidth = 400;
    const maxHeight = 400;

    const image = new Image();
    image.src = dataUrl;

    image.onload = () => {
      const { width, height } = image;
      const shouldResize = width > maxWidth || height > maxHeight;

      if (!shouldResize) {
        callback(dataUrl);
        return;
      }

      let newWidth, newHeight;
      if (width > height) {
        newHeight = (height * maxWidth) / width;
        newWidth = maxWidth;
      } else {
        newWidth = (width * maxHeight) / height;
        newHeight = maxHeight;
      }

      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;
      const context = canvas.getContext("2d");
      if (context) {
        context.drawImage(image, 0, 0, newWidth, newHeight);
        const resizedDataUrl = canvas.toDataURL("image/jpg", 0.5);
        callback(resizedDataUrl);
      }
    };

    image.onerror = () => {
      alert("There was an error processing your file!");
    };
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const fileTypes = ["jpeg", "jpg", "png", "pdf"];
    const extension = file.name.split(".").pop()?.toLowerCase();
    if (!extension || !fileTypes.includes(extension)) {
      alert("Invalid file type. Please upload a jpeg, jpg, png, or pdf.");
      return;
    }

    const reader = new FileReader();
    reader.onload = (upload) => {
      if (upload.target?.result) {
        const uploadData = {
          documentType,
          dataUrl: upload.target.result as string,
          filename: file?.name,
          filetype: extension,
        };

        setFilename(file.name);

        if (extension === "pdf") {
          setThumbDataUrl(null);
        } else {
          resizeImage(upload.target.result as string, (resizedUrl) => {
            setThumbDataUrl(resizedUrl);
          });
        }

        onChange?.(uploadData, id);
      }
    };

    reader.readAsDataURL(file);
  };

  const handleClear = () => {
    onChange?.(null, id);
    setFilename(null);
    setThumbDataUrl(null);
  };

  return (
    <Box className={className}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextInput
            label=""
            value={filename || ""}
            readOnly
            size="small"
            variant="outlined"
            endAdornmentIcon={filename && <Close />}
            endAdornmentClick={handleClear}
            error={error}
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="outlined"
            component="label"
            size="small"
            sx={{
              width: "100%",
              height: "45px",
              color: "#00db8f",
              border: ".5px solid #838588",
              ":hover": { border: ".5px solid #838588 !important" },
              ":focus": { border: ".5px solid #838588 !important" },
            }}
            startIcon={<Upload />}
          >
            Choose File
            <input
              ref={inputRef}
              type="file"
              id={id}
              hidden
              accept=".jpeg, .jpg, .png, .pdf"
              onChange={handleChange}
            />
          </Button>
        </Grid>
      </Grid>
      {showPreview && thumbDataUrl && (
        <Box mt={2}>
          <img
            src={thumbDataUrl}
            alt="Preview"
            style={{ maxWidth: "100%", maxHeight: 200 }}
          />
        </Box>
      )}
      <Box mt={2} display="flex" gap={1}>
        {canAdd && (
          <Button variant="contained" color="primary" onClick={onAdd}>
            Add
          </Button>
        )}
        {canDelete && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => onDelete?.(id)}
          >
            Delete
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default FileUploadField;

import React, { useEffect, useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  TablePagination,
} from "@mui/material";
import { getLoanHistory } from "../../services/loanPortalService";
import { Loan, LoanHistoryResponse } from "../../types/genericType";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/Loader";

const LoanHistory = () => {
  const [loans, setLoans] = useState<Loan[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalLoans, setTotalLoans] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();

  const fetchLoans = async (page: number, perPage: number) => {
    setLoading(true);
    setError(null);
    try {
      const response: LoanHistoryResponse = await getLoanHistory(page, perPage);
      setLoans(response.resources?.filter((loan) => loan?.status) || []);
      setTotalLoans(response.count || 0);
    } catch (err) {
      console.error("Error fetching loan data:", err);
      setError("Failed to load loan data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLoans(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const tableHeaders = useMemo(
    () => [
      "Loan Number",
      "Status",
      "Origination Date",
      "Amount",
      "Interest Rate",
      "View",
    ],
    []
  );

  const headerStyle = { backgroundColor: "lightgray", color: "#6d6e71" };
  const rowStyle = {
    backgroundColor: "#f7f8f8",
    color: "#36454F",
    align: "center",
  };

  const colStyle = {
    color: "#808184",
    backgroundColor: "#e7e7e7",
    maxWidth: "200px",
    fontSize: "14px",
    fontFamily: "'AvenirNext-Regular'",
    padding: "8px",
    textAlign: "center",
    fontWeight: "bold",
  };

  const cellStyle = {
    color: "#808184",
    backgroundColor: "#f7f8f8",
    fontFamily: "AvenirNext-Regular",
    fontSize: "14px",
    padding: "2px 20px",
  };

  return (
    <div>
      <Typography sx={{ fontSize: "12px", color: "#5a5a5b", mb: 1 }}>
        ACCOUNT
      </Typography>
      <Typography
        sx={{ fontSize: "24px", color: "#00db8f", mb: 1, fontWeight: "500" }}
      >
        Loan History
      </Typography>

      {loading ? (
        <Loader />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="loan history table">
              <TableHead sx={{ backgroundColor: "grey" }}>
                <TableRow>
                  <TableCell colSpan={6} sx={{ color: "white" }}>
                    Loans
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableHead sx={{ ...headerStyle }}>
                <TableRow>
                  {tableHeaders.map((col) => (
                    <TableCell key={col} sx={{ ...colStyle }}>
                      {col}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {loans.map((loan) => (
                  <TableRow key={loan.loanId} sx={{ ...rowStyle }}>
                    <TableCell sx={{ ...cellStyle }}>{loan.loanId}</TableCell>
                    <TableCell sx={{ ...cellStyle }}>{loan.status}</TableCell>
                    <TableCell sx={{ ...cellStyle }}>
                      {new Intl.DateTimeFormat("en-GB", {
                        timeZone: "UTC", // Force UTC conversion
                        day: "numeric",
                        month: "short",
                      }).format(new Date(loan.originationDate))}
                    </TableCell>
                    <TableCell sx={{ ...cellStyle }}>
                      US${loan.amount.toFixed(2)}
                    </TableCell>
                    <TableCell sx={{ ...cellStyle }}>
                      {loan.originalApr}%
                    </TableCell>
                    <TableCell sx={{ ...cellStyle }}>
                      <Button
                        sx={{ color: "#00db8f", textTransform: "capitalize" }}
                        onClick={() =>
                          navigate(`/loans/${loan.loanId}/payments`)
                        }
                      >
                        Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalLoans}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </>
      )}
    </div>
  );
};

export default LoanHistory;

import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Typography,
  Box,
  Grid,
  Container,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import {
  saveBankAccount,
  getBankAccounts,
  getBankInfo,
  makePrimaryBankAccount,
} from "../../services/bankService";
import { BankAccountType } from "../../types/genericType";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import TextInput from "../common/TextInput";
import {
  AddOutlined,
  StarBorderOutlined,
  StarOutlined,
} from "@mui/icons-material";
import Constants from "../../constants/constant";
import Dropdown from "../common/Dropdown";
import SampleCheckModal from "../../pages/Modals/SampleCheckModal";
import { validateBankAccount } from "../../utils/utils";

const BankAccount = () => {
  const [bankAccounts, setBankAccounts] = useState<BankAccountType[]>([]);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState<BankAccountType>({
    bankName: "",
    bankRoutingNumber: "",
    bankAccountNumber: "",
    bankAccountType: "",
  });
  const [errors, setErrors] = useState({
    bankRoutingNumber: "",
    bankAccountNumber: "",
    bankName: "",
    bankAccountType: "",
  });
  const [open, setOpen] = useState(false);
  const editFieldsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (editMode && editFieldsRef.current) {
      const offset = 300;
      const elementPosition =
        editFieldsRef.current.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, [editMode]);

  useEffect(() => {
    fetchBankAccounts();
  }, []);

  const handleBankAccountChange = async (field: string, value: string) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));

    if (field === "bankRoutingNumber" && value.length === 9) {
      try {
        const bankName = await getBankInfo(value);
        setFormData((prevFormData) => ({
          ...prevFormData,
          bankName,
        }));
      } catch (error) {
        console.error("Failed to fetch bank info:", error);
      }
    }
    setErrors((prev) => ({
      ...prev,
      [field]: "",
    }));
  };

  const fetchBankAccounts = async () => {
    try {
      const accounts = await getBankAccounts();
      setBankAccounts(accounts);
    } catch (error) {
      console.error("Failed to fetch bank accounts", error);
    }
  };

  const handleAddAccount = async () => {
    try {
      await saveBankAccount(formData);
      fetchBankAccounts();
    } catch (error) {
      console.error("Failed to add account", error);
    }
  };

  const handleMakePrimary = async (accountId: number) => {
    if (accountId) {
      try {
        await makePrimaryBankAccount(accountId.toString());
        fetchBankAccounts();
      } catch (error) {
        console.error("Failed to make account primary", error);
      }
    }
  };

  const handleCancel = () => {
    setFormData({
      bankName: "",
      bankRoutingNumber: "",
      bankAccountNumber: "",
      bankAccountType: "",
    });
    setErrors({
      bankRoutingNumber: "",
      bankAccountNumber: "",
      bankName: "",
      bankAccountType: "",
    });
    setEditMode(false);
  };

  const handleSave = async () => {
    const [isValid, newErrors] = validateBankAccount(formData);

    if (!isValid) {
      setErrors(newErrors);
      return;
    }

    await handleAddAccount();
    handleCancel();
  };

  return (
    <Box mt={3}>
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6" sx={{ mb: 2, color: "#838588" }}>
            Bank Information
          </Typography>
          {!editMode && (
            <Button
              variant="text"
              sx={{
                color: "#00db8f",
                textTransform: "none",
                alignItems: "center",
                mb: 2,
              }}
              onClick={() => setEditMode(true)}
            >
              <AddOutlined
                sx={{
                  fontSize: "18px",
                  color: "#00db8f",
                }}
              />
              Add Bank Account
            </Button>
          )}
        </Box>
        {bankAccounts?.map((account) => (
          <Grid container spacing={2} key={account.id}>
            {!account.isPrimaryBankAccount && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  mt: 5,
                  width: "100%",
                  mb: -4,
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    color: "#00db8f",
                    textTransform: "none",
                    alignItems: "center",
                  }}
                  onClick={() => account.id && handleMakePrimary(account.id)}
                >
                  <StarBorderOutlined
                    sx={{ fontSize: "18px", color: "#00db8f" }}
                  />
                  Make Primary
                </Button>
              </Box>
            )}
            <Grid item xs={12}>
              <TextInput
                label="Bank Name"
                value={account.bankName}
                readOnly
                endAdornmentIcon={
                  account?.isPrimaryBankAccount && <StarOutlined />
                }
                endAdornmentText={
                  account?.isPrimaryBankAccount ? "Primary Bank Account" : ""
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="Routing Number"
                value={account.bankRoutingNumber}
                readOnly
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="Account Number"
                value={account.bankAccountNumber}
                readOnly
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="Account Type"
                value={account.bankAccountType}
                readOnly
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="Life of Account"
                value={account.bankAccountAge ?? ""}
                readOnly
              />
            </Grid>
          </Grid>
        ))}
        {editMode && (
          <Grid container spacing={2} sx={{ mt: 2 }} ref={editFieldsRef}>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                Routing Number
              </Typography>
              <TextField
                variant="outlined"
                value={formData.bankRoutingNumber}
                onChange={(e) =>
                  handleBankAccountChange("bankRoutingNumber", e.target.value)
                }
                fullWidth
                size="small"
                placeholder="Enter Routing Number"
                error={!!errors.bankRoutingNumber}
                helperText={errors.bankRoutingNumber}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setOpen(true)}>
                        <HelpOutlineIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                Account Number
              </Typography>
              <TextField
                variant="outlined"
                value={formData.bankAccountNumber}
                onChange={(e) =>
                  handleBankAccountChange("bankAccountNumber", e.target.value)
                }
                fullWidth
                size="small"
                placeholder="Enter Account Number"
                error={!!errors.bankAccountNumber}
                helperText={errors.bankAccountNumber}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setOpen(true)}>
                        <HelpOutlineIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                Bank Name
              </Typography>
              <TextField
                variant="outlined"
                value={formData.bankName}
                onChange={(e) =>
                  handleBankAccountChange("bankName", e.target.value)
                }
                fullWidth
                size="small"
                placeholder="Enter Bank Name"
                error={!!errors.bankName}
                helperText={errors.bankName}
              />
            </Grid>
            <Grid item xs={12}>
              <Dropdown
                id="bankAccountType"
                label="Account Type"
                value={formData.bankAccountType || ""}
                options={Constants.bankAccountTypeOptions.map((option) => ({
                  value: option.value,
                  label: option.name,
                }))}
                onChange={(e) => handleBankAccountChange("bankAccountType", e)}
                required={true}
                error={errors.bankAccountType}
              />
            </Grid>
          </Grid>
        )}
        <SampleCheckModal open={open} handleClose={() => setOpen(false)} />
        <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
          {editMode && (
            <>
              <Button
                variant="text"
                sx={{
                  color: "#838588",
                  textTransform: "none",
                  marginRight: 2,
                }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  color: "white",
                  backgroundColor: "#00db8f",
                  textTransform: "none",
                  "&:hover": {
                    color: "white",
                    backgroundColor: "#00db8f",
                  },
                }}
                onClick={handleSave}
              >
                Save
              </Button>
            </>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default BankAccount;

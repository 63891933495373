import React from "react";
import { Backdrop, Box } from "@mui/material";
import Loader from "./Loader";

type Props = {
  loading: boolean;
  message: string;
};

const BackdropLoader = (props: Props) => {
  const { loading, message } = props;
  return (
    <Backdrop
      open={loading}
      sx={{ backgroundColor: "white", opacity: 0.7, zIndex: 9999 }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: { xs: "90%", sm: "70%" },
        }}
      >
        <Loader message={message} />
      </Box>
    </Backdrop>
  );
};

export default BackdropLoader;

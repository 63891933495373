import React, { useState } from "react";
import LoanAgreementViewer from "../LoanAgreement/AgreementViewer";
import LoanSignature from "./LoanSignature";
import { signLoanAgreement } from "../../services/loanPortalService";
import { useNavigate } from "react-router-dom";
import { Backdrop, Box, Container, Typography } from "@mui/material";
import Loader from "../../components/loader/Loader";
import ErrorComp from "../../components/errorComp/ErrorComp";

const SignLoanAgreement = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (signatureJson: any) => {
    setLoading(true);
    try {
      await signLoanAgreement(signatureJson);
      setLoading(false);
      navigate("/Portal/complete");
    } catch (e: any) {
      setError(e?.details ?? "An error occurred while signing the agreement");
      setLoading(false);
    }
  };

  return (
    <>
      {error && (
        <Container>
          <ErrorComp message={error} onClose={() => setError("")} />
        </Container>
      )}
      <LoanAgreementViewer isAdditionalAgreement={true} />
      <Typography
        variant="body2"
        sx={{
          fontSize: "11pt",
          textAlign: "left",
          fontWeight: "500",
          fontFamily: "AvenirNext-DemiBold",
          mt: 4,
          mb: 1,
          color: "#838588",
        }}
      >
        Step 2: Verify Personal Info
      </Typography>
      <LoanSignature signLoanAgreementMethod={handleSubmit} />
      <Backdrop
        open={loading}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          zIndex: 9999,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "800px",
          }}
        >
          <Loader />
        </Box>
      </Backdrop>
    </>
  );
};

export default SignLoanAgreement;

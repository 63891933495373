class ChirpLink {
  constructor(options) {
    this.onLoad = options.onLoad || function () {};
    this.onSuccess = options.onSuccess || function () {};
    this.onClose = options.onClose || function () {};
    this.onBankSelect = options.onBankSelect || function () {};
    this.onAttempt = options.onAttempt || function () {};
    this.onError = options.onError || function () {};
    this.token = options.token;
    this.mode = options.mode;

    this.requestCode = "";

    fetch(
      `https://chirp.digital/api/getRequestCodeForVerification/${this.token}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          return Promise.reject("Token not found or invalid");
        } else {
          return response.json();
        }
      })
      .then((data) => {
        if (data.success) {
          this.requestCode = data.requestCode;
          this.loadVerification();
        } else {
          this.onError({ success: false, errorMessage: data.errorDescription });
        }
      })
      .catch((error) => {
        if (
          this.mode === "ADAPTABLE" &&
          !document.getElementById("chirpLinkWidget")
        ) {
          this.onError({
            success: false,
            errorMessage:
              "An element with id 'chirpLinkWidget' is required for ADAPTABLE mode",
          });
        } else if (
          this.mode === "FULL_WIDTH" &&
          !document.getElementById("chirpLinkWidget")
        ) {
          this.onError({
            success: false,
            errorMessage:
              "An element with id 'chirpLinkWidget' is required for FULL_WIDTH mode",
          });
        } else {
          this.onError({ success: false, errorMessage: error });
        }
      });

    if (this.mode === "ADAPTABLE") {
      this.chirpVerification = document.getElementById("chirpLinkWidget");
      this.iframeContainer = document.createElement("div");
      this.iframeContainer.id = "adaptableContainer";
      if (this.chirpVerification)
        this.chirpVerification.appendChild(this.iframeContainer);
    } else if (this.mode === "FULL_WIDTH") {
      this.chirpVerification = document.getElementById("chirpLinkWidget");
      this.iframeContainer = document.createElement("div");
      this.iframeContainer.id = "fullWidthContainer";
      if (this.chirpVerification)
        this.chirpVerification.appendChild(this.iframeContainer);
    } else {
      this.chirpVerification = document.createElement("div");
      this.chirpVerification.id = "chirpVerification";
      document.body.appendChild(this.chirpVerification);
      this.iframeContainer = document.createElement("div");
      this.iframeContainer.id = "iframeContainer";
      this.chirpVerification.appendChild(this.iframeContainer);
    }
    this.closePage = "CUSTOMER_CONSENT";

    this.initStyles();
  }

  initStyles() {
    const styles = `
          #chirpVerification {
            position: fixed;
            display: none;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgb(133,216,247);
            background: linear-gradient(135deg, rgba(133,216,247,1) 0%, rgba(247,149,62,0.25) 100%);
            z-index:1000
          }
    
          #iframeContainer {
            z-index:1000;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 25%;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
            border-radius: 16px;
            height: 85%;
            max-height: 650px
          }
  
          #adaptableContainer {
           width:100%;
           height: 100%;
          }
  
           #fullWidthContainer{
            max-width: 384px;
            height: 600px;
            padding: 5%;
            margin: 0% auto;
          }
  
          /* @media screen and (max-height: 700px) {
            #iframeContainer {
          height: 50%;
            }
          }*/
  
          @media screen and (max-width: 1300px) {
            #iframeContainer {
            width: 38%;
            }
          }
    
          #closeBtn {
            z-index: 1001;
            position: absolute;
            bottom: 0%;
            border-top: 1px solid black; 
            border-radius: 0px 0px 16px 16px; 
            padding: 2% 0% 2% 0%;
            width: 100%;
            text-align: center;
            color: black;
            background: white;
            font-size: 16px;
            cursor: pointer;
            margin: 0px;
            font-family: Montserrat, sans-serif;
            font-weight: normal
          }
  
          #closeBtn::before {
            content: '';
            position: absolute;
            top: -10px; /* Adjust this value to control the extent of the shade */
            left: 0;
            width: 100%;
            height: 10px; /* Adjust this value to control the height of the shade */
            background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
          }
          #load{
            display: table;
            width: 100%;
            height: 100%;
            background-color: white;
          }
          #loadText{
            display: table-cell;
            vertical-align: middle;
            width: 100%;
            text-align: center;
            font-size: 18px;
            color: #535353;
            font-family: Montserrat, sans-serif;
          }
          #myIframe {
            width: 100%;
            height: 95%;
            display: none;
            background-color: white;
            border: 0px;
          }
  
          @media only screen and (max-width: 600px) {
            #iframeContainer {
              width: 100%;
              height: 100%;
            }
          }
        `;

    const styleElement = document.createElement("style");
    styleElement.textContent = styles;
    document.head.appendChild(styleElement);
  }

  on() {
    this.chirpVerification.style.display = "block";
  }

  close() {
    this.onClose({ pageClosed: true, closedPage: this.closePage });
  
    if (this.mode === "ADAPTABLE" || this.mode === "FULL_WIDTH") {
      if (this.iframeContainer && this.iframeContainer.parentNode) {
        this.iframeContainer.remove();
      }
    } else {
      if (this.chirpVerification && document.body.contains(this.chirpVerification)) {
        document.body.removeChild(this.chirpVerification);
      }
      if (this.iframeContainer) {
        this.iframeContainer.innerHTML = "";
      }
    }
  }

  loadVerification() {
    const iframe = document.createElement("iframe");
    iframe.id = "myIframe";
    if (!this.mode || this.mode === "POPUP" || this.mode === "FULL_WIDTH")
      iframe.style.borderRadius = "16px";

    iframe.src = `https://chirp.digital/api/widget?requestCode=${this.requestCode}&token=${this.token}&chirpAPIVerificationWidgetv2=true`;
    iframe.allowfullscreen = true;
    iframe.frameborder = "0";

    const loading = document.createElement("div");
    loading.id = "load";

    const paragraph = document.createElement("p");
    paragraph.id = "loadText";
    paragraph.textContent = "Loading...";

    loading.appendChild(paragraph);

    let offBtn;
    if (!this.mode || this.mode === "POPUP") {
      offBtn = document.createElement("p");
      offBtn.id = "closeBtn";
      offBtn.innerHTML = "Close";
      offBtn.onclick = () => {
        this.close();
      };
    }
    iframe.onload = () => {
      loading.style.display = "none";
      iframe.style.display = "block";
      // this.onLoad({ requestCode: this.requestCode });
    };

    this.iframeContainer.innerHTML = "";
    this.iframeContainer.appendChild(loading);
    if (!this.mode || this.mode === "POPUP")
      this.iframeContainer.appendChild(offBtn);
    this.iframeContainer.appendChild(iframe);

    this.on();

    const handleMessage = (event) => {
      if (event.data === "windowClose") {
        this.close();
      }
      if (event.data.onLoad) {
        delete event.data.onLoad;
        this.onLoad(event.data);
      }
      if (event.data.onSuccess) {
        delete event.data.onSuccess;
        this.onSuccess(event.data);
      }
      if (event.data.onError) {
        delete event.data.onError;
        this.onError(event.data);
      }
      if (event.data.bankSelected) {
        this.onBankSelect(event.data);
      }
      if (event.data.attempted) {
        this.onAttempt(event.data);
      }
      if (event.data.currentPage) {
        this.closePage = event.data.closePage;
      }
    };
    if (!window.messageEventListenerAdded) {
      window.addEventListener("message", handleMessage);
      window.messageEventListenerAdded = true;
    }
  }
}

export default ChirpLink;

import React, { useState, useCallback, useEffect } from "react";
import { TextField, Grid, Typography, Box, Button } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import IconButton from "../../components/IconBtn/IconBtn";
import AmountInputField from "../../components/common/AmountField";
import Dropdown from "../../components/common/Dropdown";
import {
  Address,
  CheckEligibilityResponse,
  IncomeType,
  LeadsRequest,
  PayDay,
} from "../../types/genericType";
import HelpIncomeModal from "../Modals/HelpIncomeModal";
import Constants from "../../constants/constant";
import IncomeSource from "./IncomeSource";
import { updateLead } from "../../services/leadsService";
import { checkValidations } from "../../services/loanPortalService";
import { useNavigate } from "react-router-dom";
import { isValid, removeBlankFields } from "../../utils/utils";
import BackdropLoader from "../../components/loader/BackdropLoader";

interface StepThreeProps {
  prevStep: () => void;
  nextStep: () => void;
}

const initialIncomeSourceError = {
  incomeType: "",
  employer: "",
  employerAddress: {
    zip: "",
    city: "",
    state: "",
  },
  workPhoneNumber: "",
  timeAtEmployment: "",
  payDay: {
    payrollType: "",
    payFrequency: "",
    payDay: "",
    nextPayDate: "",
    monthlyPayDayType: "",
    firstPayWeek: "",
    firstMonthlyPayDayOfWeek: "",
    firstMonthlyPayDay: "",
    secondPayWeek: "",
    secondMonthlyPayDayOfWeek: "",
    secondMonthlyPayDay: "",
  },
};

const StepThree: React.FC<StepThreeProps> = ({ prevStep, nextStep }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [storageData, setStorageData] = useState<any>({});
  const navigate = useNavigate();
  const initialIncomeSource: IncomeType = {
    id: null,
    monthlyIncome: 0,
    incomeType: "employment",
    employer: "",
    employerAddress: {
      city: "",
      state: "",
      zip: "",
    },
    timeAtEmployment: "",
    workPhoneNumber: "",
    payDay: {
      payrollType: "",
      payFrequency: "",
      payDay: "",
      nextPayDate: "",
      monthlyPayDayType: "",
      firstPayWeek: "",
      firstMonthlyPayDayOfWeek: "",
      firstMonthlyPayDay: "",
      secondPayWeek: "",
      secondMonthlyPayDayOfWeek: "",
      secondMonthlyPayDay: "",
    },
    otherIncomeDescription: "",
  };

  const [formData, setFormData] = useState({
    monthlyIncome: "",
    licenseNumber: "",
    licenseState: "",
    incomeSources: [initialIncomeSource],
  });

  const [errors, setErrors] = useState({
    monthlyIncome: "",
    licenseNumber: "",
    licenseState: "",
    incomeSources: [initialIncomeSourceError],
  });

  useEffect(() => {
    const storedData = sessionStorage.getItem("LoanApplication");
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        setStorageData(parsedData);
        setFormData((prevData) => ({
          ...parsedData,
          incomeSources: parsedData.incomeSources || prevData.incomeSources,
          monthlyIncome: parsedData.monthlyIncome || prevData.monthlyIncome,
          licenseNumber: parsedData.licenseNumber || prevData.licenseNumber,
          licenseState: parsedData.licenseState || prevData.licenseState,
        }));
      } catch (error) {
        console.error("Error parsing storedData from sessionStorage:", error);
      }
    }
  }, []);

  const updateSessionStorage = (
    data: Partial<{
      incomeSources: IncomeType[];
      monthlyIncome: string;
      licenseNumber: string;
      licenseState: string;
    }>
  ) => {
    const existingData = sessionStorage.getItem("LoanApplication");
    const existingDataObj = existingData ? JSON.parse(existingData) : {};
    const updatedData = {
      ...existingDataObj,
      ...data,
    };
    sessionStorage.setItem("LoanApplication", JSON.stringify(updatedData));
  };
  const validate = useCallback(() => {
    const isRequired = (value: any) => (value ? "" : "Required field");
    const validatePayDay = (payDay: PayDay) => {
      const errors: any = {
        payrollType: isRequired(payDay.payrollType),
        payFrequency: isRequired(payDay.payFrequency),
        payDay:
          payDay.payFrequency === "weekly" || payDay.payFrequency === "biWeekly"
            ? isRequired(payDay.payDay)
            : "",
        nextPayDate:
          payDay.payFrequency === "biWeekly" && payDay.payDay
            ? isRequired(payDay.nextPayDate)
            : "",
        monthlyPayDayType:
          payDay.payFrequency === "monthly" ||
          payDay.payFrequency === "twiceMonthly"
            ? isRequired(payDay.monthlyPayDayType)
            : "",
        firstPayWeek:
          (payDay.payFrequency === "monthly" ||
            payDay.payFrequency === "twiceMonthly") &&
          payDay.monthlyPayDayType === "DayOfWeek"
            ? isRequired(payDay.firstPayWeek)
            : "",
        firstMonthlyPayDayOfWeek:
          (payDay.payFrequency === "monthly" ||
            payDay.payFrequency === "twiceMonthly") &&
          payDay.monthlyPayDayType === "DayOfWeek"
            ? isRequired(payDay.firstMonthlyPayDayOfWeek)
            : "",
        firstMonthlyPayDay:
          (payDay.payFrequency === "monthly" ||
            payDay.payFrequency === "twiceMonthly") &&
          payDay.monthlyPayDayType === "Day"
            ? isRequired(payDay.firstMonthlyPayDay)
            : "",
        secondPayWeek:
          payDay.payFrequency === "twiceMonthly" &&
          payDay.monthlyPayDayType === "DayOfWeek"
            ? isRequired(payDay.secondPayWeek)
            : "",
        secondMonthlyPayDayOfWeek:
          payDay.payFrequency === "twiceMonthly" &&
          payDay.monthlyPayDayType === "DayOfWeek"
            ? isRequired(payDay.secondMonthlyPayDayOfWeek)
            : "",
        secondMonthlyPayDay:
          payDay.payFrequency === "twiceMonthly" &&
          payDay.monthlyPayDayType === "Day"
            ? isRequired(payDay.secondMonthlyPayDay)
            : "",
      };
      return errors;
    };
    const newErrors = {
      monthlyIncome: isRequired(formData.monthlyIncome),
      licenseNumber: isRequired(formData.licenseNumber),
      licenseState: isRequired(formData.licenseState),
      incomeSources: formData.incomeSources.map((source) => ({
        incomeType: isRequired(source.incomeType),
        employer:
          source.incomeType === "employment" ? isRequired(source.employer) : "",
        employerAddress: {
          zip:
            source.incomeType === "employment"
              ? isRequired(source.employerAddress.zip)
              : "",
          city:
            source.incomeType === "employment"
              ? isRequired(source.employerAddress.city)
              : "",
          state:
            source.incomeType === "employment"
              ? isRequired(source.employerAddress.state)
              : "",
        },
        workPhoneNumber:
          source.incomeType === "employment" ||
          source.incomeType === "selfEmployed"
            ? isRequired(source.workPhoneNumber)
            : "",
        timeAtEmployment:
          source.incomeType === "employment" ||
          source.incomeType === "selfEmployed"
            ? isRequired(source.timeAtEmployment)
            : "",
        payDay: validatePayDay(source.payDay),
      })),
    };

    setErrors(newErrors);
    const validationResult = isValid(newErrors);
    return validationResult;
  }, [formData]);

  const handleChange = (field: string, value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));
  };

  const handleIncomeSourceChange = (
    index: number,
    field: keyof typeof initialIncomeSource,
    value: any
  ) => {
    setFormData((prevFormData) => {
      const updatedIncomeSources = prevFormData.incomeSources.map(
        (source, i) => {
          if (i === index) {
            if (source.payDay && field in source.payDay) {
              return {
                ...source,
                payDay: { ...source.payDay, [field]: value },
              };
            } else {
              return { ...source, [field]: value };
            }
          }
          return source;
        }
      );

      return { ...prevFormData, incomeSources: updatedIncomeSources };
    });

    setErrors((prevErrors) => {
      const updatedErrors = prevErrors.incomeSources.map((source, i) => {
        if (i === index) {
          if (source.payDay && field in source.payDay) {
            return {
              ...source,
              payDay: { ...source.payDay, [field]: "" },
            };
          } else {
            return { ...source, [field]: "" };
          }
        }
        return source;
      });

      return { ...prevErrors, incomeSources: updatedErrors };
    });
  };

  const handleAddressChange = (
    index: number,
    updatedAddress: Partial<Address> & { id: string; value: string }
  ) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      incomeSources: prevErrors.incomeSources.map((source, i) =>
        i === index
          ? {
              ...source,
              employerAddress: {
                ...source.employerAddress,
                [updatedAddress.id]: "",
              },
            }
          : source
      ),
    }));
    setFormData((prevData) => {
      const updatedIncomeSources = prevData.incomeSources.map((source, i) =>
        i === index
          ? {
              ...source,
              employerAddress: {
                ...source.employerAddress,
                [updatedAddress.id]: updatedAddress.value,
              },
            }
          : source
      );

      return {
        ...prevData,
        incomeSources: updatedIncomeSources,
      };
    });
  };

  const handleAddIncomeSource = () => {
    if (formData.incomeSources.length < 2) {
      setFormData({
        ...formData,
        incomeSources: [...formData.incomeSources, initialIncomeSource],
      });
      setErrors({
        ...errors,
        incomeSources: [...errors.incomeSources, initialIncomeSourceError],
      });
    }
  };

  const handleRemoveIncomeSource = (index: number) => {
    setFormData({
      ...formData,
      incomeSources: formData.incomeSources.filter((_, i) => i !== index),
    });
  };

  useEffect(() => {
    updateSessionStorage({
      incomeSources: formData.incomeSources,
      monthlyIncome: formData.monthlyIncome,
      licenseNumber: formData.licenseNumber,
      licenseState: formData.licenseState,
    });
  }, [formData]);

  const updateLeadData = async () => {
    const leadData = sessionStorage.getItem("Lead");
    if (leadData) {
      try {
        const parsedLeadData = JSON.parse(leadData);
        const updatedLeadData: LeadsRequest = {
          ...parsedLeadData,
          monthlyIncome: formData.monthlyIncome,
        };
        await updateLead(updatedLeadData);
        sessionStorage.setItem("Lead", JSON.stringify(updatedLeadData));
      } catch (error) {
        console.error("Error parsing leadData from sessionStorage:", error);
      }
    }
  };

  const checkValidationsEligibility = async () => {
    try {
      setLoading(true);
      const response: CheckEligibilityResponse = await checkValidations({
        validations: ["income", "address", "timeEmployed", "payPeriod"],
        activeMilitary: true,
        address: storageData?.primaryAddress,
        incomeSources: removeBlankFields(formData.incomeSources),
        monthlyIncome: formData.monthlyIncome,
      });

      if (response?.allow) {
        nextStep();
      } else {
        const reason =
          response.validation === "username" ? "existing-user" : "address";
        navigate(`/sorry/${reason}`, {
          state: {
            error: {
              message: response.message,
              details: response.rule,
            },
          },
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleNext = () => {
    if (validate()) {
      updateLeadData();
      checkValidationsEligibility();
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            Monthly Net Income
          </Typography>
          <AmountInputField
            label=""
            amount={formData.monthlyIncome}
            setAmount={(value: string) => handleChange("monthlyIncome", value)}
            error={!!errors.monthlyIncome}
            helperText={errors.monthlyIncome}
            showHelperText={() => {
              setOpen(true);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            Driving License
          </Typography>
          <TextField
            variant="outlined"
            value={formData.licenseNumber}
            onChange={(e) => handleChange("licenseNumber", e.target.value)}
            fullWidth
            size="small"
            placeholder="Enter Driving License"
            error={!!errors.licenseNumber}
            helperText={errors.licenseNumber}
          />
        </Grid>
        <Grid item xs={6}>
          <Dropdown
            id="state"
            label=""
            value={formData.licenseState}
            options={Constants.stateOptions.map((option) => ({
              value: option.value,
              label: option.name,
            }))}
            onChange={(val) => handleChange("licenseState", val)}
            required={true}
            error={errors.licenseState}
          />
        </Grid>
        {formData.incomeSources.map((incomeSource, index) => (
          <IncomeSource
            incomeSource={incomeSource}
            index={index}
            key={index}
            errors={errors.incomeSources[index]}
            handleIncomeSourceChange={handleIncomeSourceChange}
            handleAddressChange={handleAddressChange}
            handleRemoveIncomeSource={handleRemoveIncomeSource}
          />
        ))}
        {formData.incomeSources.length < 2 && (
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="start"
              alignItems="center"
              sx={{
                color: "#00db8f",
                fontWeight: 500,
                cursor: "pointer",
                "&:hover": {
                  color: "#00594f",
                },
              }}
              onClick={handleAddIncomeSource}
            >
              <Typography
                sx={{
                  fontFamily: "AvenirNext-DemiBold",
                  marginLeft: "4px",
                }}
              >
                + Add Second Income Source
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
      <Box display="flex" justifyContent="center" mt={4}>
        <IconButton
          icon={<LockIcon sx={{ fontSize: "18px", color: "#00db8f" }} />}
          handleBtnClick={handleNext}
          title="SAVE & CONTINUE"
          type="contained"
        />
      </Box>
      <Box display="flex" justifyContent="center" mt={2}>
        <Button
          variant="text"
          onClick={prevStep}
          sx={{
            color: "#00db8f",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          Go Back
        </Button>
      </Box>
      <HelpIncomeModal open={open} handleClose={() => setOpen(false)} />
      <BackdropLoader loading={loading} message="" />
    </>
  );
};

export default StepThree;

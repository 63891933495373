import { Typography, FormControlLabel, Checkbox } from "@mui/material";
import React, { useState } from "react";
import { sendApprovalNotification } from "../../services/adminService";
import GenericButton from "../../components/common/GenericButton";
import BackdropLoader from "../../components/loader/BackdropLoader";

type Props = {
  customerId: string;
  setApiError: Function;
  setApiSuccess: Function;
};

const SignLoanRequest = (props: Props) => {
  const { customerId, setApiError, setApiSuccess } = props;
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(false);
  const [textMessage, setTextMessage] = useState(true);

  const handleSubmit = async () => {
    if (!email && !textMessage) {
      setApiError("You must select at least one contact method  ");
    } else {
      setLoading(true);
      try {
        await sendApprovalNotification({
          customerId,
          sendViaEmail: email,
          sendViaSMS: textMessage,
        });
        setLoading(false);
        setApiSuccess(
          `Sign Loan Request sent via ${
            textMessage && email
              ? "Text Message and Email"
              : textMessage
              ? "Text Message"
              : "Email"
          }`
        );
      } catch (e: any) {
        setApiError(
          e?.details ?? "An error occurred while signing the agreement"
        );
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Typography
        variant="h6"
        color="#838588"
        fontWeight="bold"
        fontSize={16}
        sx={{ mb: 1 }}
      >
        Send Approval
      </Typography>
      <Typography
        variant="h6"
        sx={{
          fontSize: "12px",
          fontWeight: "600",
          paddingLeft: "8px",
          marginBottom: "5px",
          color: "#838588",
        }}
      >
        Contact method
      </Typography>
      <FormControlLabel
        sx={{
          display: "flex",
          alignItems: "center",
        }}
        control={
          <Checkbox
            checked={email}
            onChange={(e) => setEmail(e.target.checked)}
            name="optInToReceiveTextMessage"
            color="primary"
            sx={{
              padding: "0px 9px",
              borderRadius: "5px",
              color: "#838588",
              "&.Mui-checked": {
                color: "#838588",
              },
            }}
          />
        }
        label={
          <Typography
            variant="body2"
            sx={{
              fontSize: "12px",
              color: "#838588",
              fontWeight: "600",
            }}
          >
            Email
          </Typography>
        }
      />
      <FormControlLabel
        sx={{
          display: "flex",
          alignItems: "center",
        }}
        control={
          <Checkbox
            checked={textMessage}
            onChange={(e) => setTextMessage(e.target.checked)}
            name="optInToReceiveTextMessage"
            color="primary"
            sx={{
              padding: "0px 9px",
              borderRadius: "5px",
              color: "#838588",
              "&.Mui-checked": {
                color: "#838588",
              },
            }}
          />
        }
        label={
          <Typography
            variant="body2"
            sx={{
              fontSize: "12px",
              color: "#838588",
              fontWeight: "600",
            }}
          >
            Text Message
          </Typography>
        }
      />
      <Typography sx={{ mt: 2, color: "#838588", fontSize: "12px" }}>
        Clicking 'Send Sign Loan Request' below will generate a link and send it
        to the contact method(s) specified above. The link generated is good for
        24 hours before it expires. You may re-generate a new link and send to
        the customer by coming here again if needed, or the customer can log in
        to the portal using their credentials to continue the process.
      </Typography>
      <GenericButton
        label="SEND SIGN LOAN REQUEST"
        onClick={handleSubmit}
        additionalStyles={{ mt: 2, mb: 5 }}
      />
      <BackdropLoader loading={loading} message="" />
    </>
  );
};

export default SignLoanRequest;
